import { METER_TO_FEET } from 'src/app.constants';
import { store } from 'src/store';
import {
  fetchSites,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import { postBulkUpdates } from '../../utils/useful_functions';

export const updateSite = (
  projectId,
  ids,
  objectPath,
  value,
  gridApi,
  heightUnits
) => {
  postBulkUpdates(
    projectId,
    ids,
    objectPath,
    value,
    gridApi,
    'site',
    heightUnits,
    fetchSites
  );
};

const getCommonColumns = (path, projectId) => {
  return [
    {
      field: 'name',
      sort: 'asc',
      headerName: 'Name',
      pinned: 'left',
      cellRenderer: 'linkRenderer',
      cellClass: ['custom-text'],
      cellRendererParams: {
        getHref: (data) => `${path}/${data.id}`,
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
    },
    {
      field: 'maximum_height',
      headerValueGetter: ({ context }) => {
        return `Maximum Height (${context.prefs.heightUnits})`;
      },
      headerName: 'Maximum Height',
      cellRenderer: 'heightRenderer',
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      cellEditorParams: {
        precision: 1,
        min: 0,
        max: 3000,
        step: 0.1,
      },
      onCellValueChanged: (rowData) => {
        const {
          data,
          newValue,
          oldValue,
          api,
          objectPath = 'maximum_height',
          context,
        } = rowData;
        let selectedIds = [data.id];
        const { prefs } = context;
        let selectedRows = api?.getSelectedRows();
        if (!selectedRows?.length) selectedRows = [data];
        if (api != null && selectedRows?.length) {
          selectedIds = [];
          selectedRows.forEach((row) => {
            if (prefs.heightUnits === 'ft') {
              row.maximum_height = parseFloat(newValue) / METER_TO_FEET;
            } else {
              row.maximum_height = parseFloat(newValue);
            }
            row.updated = false;
            selectedIds.push(row.id);
          });
          api.applyTransaction({ update: selectedRows });
        }
        if (parseFloat(newValue) !== parseFloat(oldValue) && api != null) {
          updateSite(
            projectId,
            selectedIds,
            objectPath,
            newValue,
            api,
            prefs.heightUnits
          );
        }
      },
    },
    {
      field: 'description',
      editable: true,
      headerName: 'Description',
      cellClass: ['ag-grid-cell-truncate-text'],
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '300',
        cols: '50',
        rows: '6',
      },
      onCellValueChanged: (rowData) => {
        const { newValue, api, data, objectPath = 'description' } = rowData;
        let selectedIds = [data.id];
        const selectedRows = api?.getSelectedRows();
        if (api != null && selectedRows.length) {
          selectedIds = [];
          selectedRows.forEach((row) => {
            const { id } = row;
            row.description = newValue;
            selectedIds.push(id);
          });
          api.applyTransaction({ update: selectedRows });
        }
        if (api) updateSite(projectId, selectedIds, objectPath, newValue, api);
      },
    },
  ];
};

const getExtraNetworkSiteColumns = (projectId) => {
  return [
    {
      field: 'node_type',
      headerName: 'Node Type',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellClass: ['select-cell'],
      cellEditorParams: {
        values: ['', 'DN', 'POP'],
      },
      onCellValueChanged: function (rowData) {
        const { newValue, api, data, objectPath = 'node_type' } = rowData;
        let selectedIds = [data.id];
        const selectedRows = api?.getSelectedRows();

        if (api != null && selectedRows.length) {
          selectedIds = [];
          selectedRows.forEach((row) => {
            const { id } = row;
            row.node_type = newValue;
            selectedIds.push(id);
          });
          api.applyTransaction({ update: selectedRows });
          store.dispatch(
            setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
          );
        }

        if (api) {
          updateSite(projectId, selectedIds, objectPath, newValue, api);
        }
      },
    },
    {
      field: 'properties.ptp_link_count',
      headerName: 'PTP Links',
    },
    {
      field: 'properties.access_point_count',
      headerName: 'PMP Network Devices',
    },
    {
      field: 'properties.mesh_link_count',
      headerName: 'Mesh Links',
    },
    {
      field: 'properties.total_sms',
      headerName: 'Number of Subscriber Modules',
    },
    {
      field: 'properties.connected_subscribers',
      headerName: 'Connected Subscribers',
    },
    {
      field: 'properties.unconnected_subscribers',
      headerName: 'Unconnected Subscribers',
    },
    {
      field: 'properties.dl_throughput',
      headerName: 'Total Predicted DL Throughput',
      isComparatorRequired: true,
    },
    {
      field: 'properties.ul_throughput',
      headerName: 'Total Predicted UL Throughput',
      isComparatorRequired: true,
    },
    {
      field: 'properties.total_throughput',
      headerName: 'Total Throughput',
      isComparatorRequired: true,
    },
    {
      field: 'properties.site_owner',
      headerName: 'Site Owner',
    },
    {
      field: 'properties.has_tower_switch',
      headerName: 'Switch',
      cellRenderer: 'yesNoRenderer',
    },
  ];
};

export const getNetworkSiteColumns = (path, projectId) => {
  return [
    ...getCommonColumns(path, projectId),
    ...getExtraNetworkSiteColumns(projectId),
  ];
};

export const getSubscriberSiteColumns = (path, projectId) => {
  return [
    ...getCommonColumns(path, projectId),
    {
      field: 'properties',
      headerName: 'PMP Links',
      valueGetter: (params) => {
        return params.data.properties.subscriber_count;
      },
    },
  ];
};
