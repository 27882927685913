import { defineMessages } from 'react-intl';

/*
 * Define additional messages in here.
 * The script "translate.py" will then export
 * these messages to en.json. All of the
 * messages can then be translated to support
 * multiple languages. These values will be
 * overwritten if the message exists in en.json
 * or the appropriate language file.
 */
export default defineMessages({
  /* Common messages */
  common: {
    id: 'common.common',
    defaultMessage: 'Common',
  },
  from: {
    id: 'common.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'common.to',
    defaultMessage: 'To',
  },
  user: {
    id: 'common.user',
    defaultMessage: 'User',
  },
  generalProperties: {
    id: 'common.generalProperties',
    defaultMessage: 'General Properties',
  },
  download: {
    id: 'common.download',
    defaultMessage: 'Download',
  },
  carrier: {
    id: 'common.carrier',
    defaultMessage: 'Carrier',
  },
  clearAll: {
    id: 'common.clearAll',
    defaultMessage: 'Clear All',
  },
  bandwidth: {
    id: 'common.bandwidth',
    defaultMessage: 'Bandwidth',
  },
  frequency: {
    id: 'common.frequency',
    defaultMessage: 'Frequency',
  },
  clearSelection: {
    id: 'common.clearSelection',
    defaultMessage: 'Clear Selection',
  },
  raster: {
    id: 'common.raster',
    defaultMessage: 'Raster',
  },
  addUser: {
    id: 'common.addUser',
    defaultMessage: 'Add user',
  },
  addUserErrorHeader: {
    id: 'common.addUserErrorHeader',
    defaultMessage: 'Error adding user',
  },
  addUserErrorContent: {
    id: 'common.addUserErrorContent',
    defaultMessage: 'Please check the email address and try again',
  },
  userAlreadyExists: {
    id: 'common.userAlreadyExists',
    defaultMessage: 'You are already the project admin.',
  },
  userEmail: {
    id: 'common.userEmail',
    defaultMessage: 'User email address...',
  },
  permissions: {
    id: 'common.permissions',
    defaultMessage: 'Permissions',
  },
  readOnly: {
    id: 'common.readOnly',
    defaultMessage: 'read only',
  },
  read: {
    id: 'common.read',
    defaultMessage: 'Read Only',
  },
  readwrite: {
    id: 'common.readwrite',
    defaultMessage: 'Read/Write',
  },
  write: {
    id: 'common.write',
    defaultMessage: 'Write',
  },
  admin: {
    id: 'common.admin',
    defaultMessage: 'Admin',
  },
  ok: {
    id: 'common.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  stop: {
    id: 'common.stop',
    defaultMessage: 'Stop',
  },
  agree: {
    id: 'common.agree',
    defaultMessage: 'Agree',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
  },
  restore: {
    id: 'common.restore',
    defaultMessage: 'Restore Default',
  },
  restoreAlertTitle: {
    id: 'common.restoreAlertTitle',
    defaultMessage: 'Restore clutter data?',
  },
  restoreAlertMsg: {
    id: 'common.restoreAlertMsg',
    defaultMessage:
      'Do you want to replace the clutter settings with the default heights and colors?',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
  },
  firstName: {
    id: 'common.fistName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'common.lastName',
    defaultMessage: 'Last Name',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
  },
  siteOwner: {
    id: 'common.siteOwner',
    defaultMessage: 'Site Owner',
  },
  openProject: {
    id: 'common.openProject',
    defaultMessage: 'Open project',
  },
  saveProject: {
    id: 'common.saveProject',
    defaultMessage: 'Save changes to the current project',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  warning: {
    id: 'common.warning',
    defaultMessage:
      'Unsaved changes will be lost. Are you sure you want to continue?',
  },
  reset: {
    id: 'common.reset',
    defaultMessage: 'Reset',
  },
  apply: {
    id: 'common.apply',
    defaultMessage: 'Apply',
  },
  offset: {
    id: 'common.offset',
    defaultMessage: 'Offset',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
  },
  downloadCSV: {
    id: 'common.downloadCSV',
    defaultMessage: 'Download CSV',
  },
  leaveProject: {
    id: 'common.leaveProject',
    defaultMessage: 'Remove from list',
  },
  discardChanges: {
    id: 'common.discardChanges',
    defaultMessage: 'Discard changes',
  },
  unsavedChanges: {
    id: 'common.unsavedChanges',
    defaultMessage: 'Project has unsaved changes',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Error',
  },
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
  },
  reportBug: {
    id: 'common.reportBug',
    defaultMessage: 'Report this bug',
  },
  unexpectedError: {
    id: 'common.unexpectedError',
    defaultMessage: 'An Unexpected Error Occurred',
  },

  showAll: {
    id: 'common.showAll',
    defaultMessage: 'Show All',
  },
  project: {
    id: 'common.project',
    defaultMessage: 'Project',
  },
  projectSettings: {
    id: 'common.projectSettings',
    defaultMessage: 'Project Settings',
  },
  createNewProject: {
    id: 'common.createNewProject',
    defaultMessage: 'Create new project',
  },
  importLINKPlannerProject: {
    id: 'common.importLINKPlannerProject',
    defaultMessage: 'Import LINKPlanner project',
  },
  importProgress: {
    id: 'common.importProgress',
    defaultMessage: 'Importing file...',
  },
  importError: {
    id: 'common.importError',
    defaultMessage: 'Error importing file',
  },
  invalidFileError: {
    id: 'common.invalidFileError',
    defaultMessage:
      'Invalid file, please choose a file with the .ptpprj or .lpp extension',
  },
  fileError: {
    id: 'common.fileError',
    defaultMessage:
      'There was a problem with the file. Please send the file to linkplanner@cambiumnetworks.com',
  },
  selectLPFile: {
    id: 'common.selectLPFile',
    defaultMessage: 'Select LINKPlanner file',
  },
  projectsTitle: {
    id: 'common.projectsTitle',
    defaultMessage: 'Create a new project or open an existing project',
  },
  projectHelp: {
    id: 'common.projectHelp',
    defaultMessage: 'Edit the project properties',
  },
  deleteProjectHelp: {
    id: 'common.deleteProjectHelp',
    defaultMessage:
      'Delete the project from your list. You can only remove a project if you have admin permissions.',
  },
  rename: {
    id: 'common.rename',
    defaultMessage: 'Rename',
  },
  duplicateProject: {
    id: 'common.duplicateProject',
    defaultMessage: 'Duplicate project',
  },
  exportProject: {
    id: 'common.exportProject',
    defaultMessage: 'Export Project',
  },
  exportKmz: {
    id: 'common.exportKmz',
    defaultMessage: 'Export to Google Earth™',
  },
  duplicateProjectHelp: {
    id: 'common.duplicateProjectHelp',
    defaultMessage: 'Create a duplicate version of this project.',
  },
  siteError: {
    id: 'common.siteError',
    defaultMessage: 'Unable to create a site in this location.',
  },
  site: {
    id: 'common.site',
    defaultMessage: 'Site',
  },
  sites: {
    id: 'common.sites',
    defaultMessage: 'Sites',
  },
  network_site: {
    id: 'common.network_site',
    defaultMessage: 'Network Site',
  },
  unassigned_network_sites: {
    id: 'unassigned_network_sites',
    defaultMessage:
      '{sitesCount} Network Site{s} {orNotorIsnot} considered for this study as the Node Type field is blank or {theyOrIt} outside of the boundary. To include {thisOrThese} Network Site{s} for this study, please update the Node Type field to DN/POP or move the site{s} inside the boundary.',
  },
  subscriber_site: {
    id: 'common.subscriber_site',
    defaultMessage: 'Subscriber Site',
  },
  networkSites: {
    id: 'common.networkSites',
    defaultMessage: 'Network Sites',
  },
  subscriberSites: {
    id: 'common.subscriberSites',
    defaultMessage: 'Subscriber Sites',
  },
  accessPoints: {
    id: 'common.accessPoints',
    defaultMessage: 'PMP Network Devices',
  },
  pmp: {
    id: 'common.pmp',
    defaultMessage: 'PMP',
  },
  performanceSummaryOfND: {
    id: 'common.performanceSummaryOfND',
    defaultMessage: 'PMP Performance Summary',
  },
  viewSite: {
    id: 'common.viewSite',
    defaultMessage: 'View site',
  },
  viewLink: {
    id: 'common.viewLink',
    defaultMessage: 'View Link',
  },
  viewAccessPoint: {
    id: 'common.viewAccessPoint',
    defaultMessage: 'View PMP network device',
  },
  sms: {
    id: 'common.sms',
    defaultMessage: 'Subscriber Modules',
  },
  pmpLinks: {
    id: 'common.pmpLinks',
    defaultMessage: 'PMP Links',
  },
  ptpLinks: {
    id: 'common.ptpLinks',
    defaultMessage: 'PTP Links',
  },
  viewsheds: {
    id: 'common.viewsheds',
    defaultMessage: 'Viewsheds',
  },
  expandSidebar: {
    id: 'common.expandSidebar',
    defaultMessage: 'Expand sidebar',
  },
  collapseSidebar: {
    id: 'common.collapseSidebar',
    defaultMessage: 'Collapse sidebar',
  },
  createLink: {
    id: 'common.createLink',
    defaultMessage: 'Create a new link',
  },
  createLinkMap: {
    id: 'common.createLinkMap',
    defaultMessage: 'Create a new link on the map',
  },
  createTemplate: {
    id: 'common.createTemplate',
    defaultMessage: 'Create a new template',
  },
  createAccessPoints: {
    id: 'common.createAccessPoints',
    defaultMessage: 'Create new PMP network devices',
  },
  coverageMap: {
    id: 'common.coverageMap',
    defaultMessage: 'Coverage Map',
  },
  kind: {
    id: 'common.kind',
    defaultMessage: 'Kind',
  },
  antennaModeledBeamwidth: {
    id: 'common.modeledBeamwidth',
    defaultMessage: 'Modeled Beamwidth',
  },
  antennaLabel: {
    id: 'common.antennaLabel',
    defaultMessage: 'Antenna Selection',
  },
  antennaSiteholder: {
    id: 'common.antennaSiteholder',
    defaultMessage: 'Select an antenna',
  },
  antennaHeight: {
    id: 'common.antennaHeight',
    defaultMessage: 'Antenna Height',
  },
  antennaAzimuth: {
    id: 'common.antennaAzimuth',
    defaultMessage: 'Antenna Azimuth',
  },
  antennaTilt: {
    id: 'common.antennaTilt',
    defaultMessage: 'Antenna Tilt',
  },
  antennaCableLoss: {
    id: 'common.antennaCableLoss',
    defaultMessage: 'Cable Loss',
  },
  calculate: {
    id: 'common.calculate',
    defaultMessage: 'Calculate',
  },
  noDataMsg: {
    id: 'common.noDataMsg',
    defaultMessage: 'No data available',
  },
  color: {
    id: 'common.color',
    defaultMessage: 'Color',
  },
  bom: {
    id: 'common.bom',
    defaultMessage: 'Bill of Materials',
  },
  antennas: {
    id: 'common.antennas',
    defaultMessage: 'Antennas',
  },
  userStats: {
    id: 'common.userStats',
    defaultMessage: 'User Stats',
  },
  pmpNetworkBom: {
    id: 'common.pmpNetworkBom',
    defaultMessage: 'PMP Network BOM',
  },
  pmpSubscribersBom: {
    id: 'common.pmpSubscribersBom',
    defaultMessage: 'PMP Subscriber Modules BOM',
  },

  target: {
    id: 'coverage.target',
    defaultMessage: 'Target',
  },
  signalLevel: {
    id: 'coverage.signalLevel',
    defaultMessage: 'Signal Level',
  },
  modMode: {
    id: 'coverage.modMode',
    defaultMessage: 'Modulation Mode',
  },
  maxModMode: {
    id: 'coverage.maxModMode',
    defaultMessage: 'Maximum Modulation Mode',
  },
  dataRate: {
    id: 'coverage.dataRate',
    defaultMessage: 'Data Rate',
  },
  palette: {
    id: 'coverage.palette',
    defaultMessage: 'Palette',
  },
  reds: {
    id: 'coverage.reds',
    defaultMessage: 'Reds',
  },
  transparency: {
    id: 'coverage.transparency',
    defaultMessage: 'Transparency',
  },

  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Are you sure?',
  },
  kilometers: {
    id: 'common.kilometers',
    defaultMessage: 'kilometers',
  },
  kilometers_short: {
    id: 'common.kilometers_short',
    defaultMessage: 'km',
  },
  miles: {
    id: 'common.miles',
    defaultMessage: 'miles',
  },
  miles_short: {
    id: 'common.miles_short',
    defaultMessage: 'mi.',
  },
  meters: {
    id: 'common.meters',
    defaultMessage: 'meters',
  },
  meters_short: {
    id: 'common.meters_short',
    defaultMessage: 'm',
  },
  feet: {
    id: 'common.feet',
    defaultMessage: 'feet',
  },
  feet_short: {
    id: 'common.feet_short',
    defaultMessage: 'ft',
  },
  antenna: {
    id: 'common.antenna',
    defaultMessage: 'Antenna',
  },
  tilt: {
    id: 'common.tilt',
    defaultMessage: 'Tilt',
  },
  requiredFieldError: {
    id: 'common.requiredFieldError',
    defaultMessage: 'Please enter {fieldName}',
  },
  requiredSelectError: {
    id: 'common.requiredSelectError',
    defaultMessage: 'Please select {fieldName}',
  },
  limitExceededError: {
    id: 'common.limitExceededError',
    defaultMessage:
      'Creating this {entityName} would exceed your maximum number of {entityName}s ({limit})',
  },
  maximumNumberError: {
    id: 'common.maximumNumberError',
    defaultMessage:
      'You have already created the maximum number of {entityName}s ({limit})',
  },
  validFieldError: {
    id: 'common.validFieldError',
    defaultMessage: 'Please enter valid {fieldName}',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Next',
  },
  total: {
    id: 'common.total',
    defaultMessage: 'Total',
  },
  previous: {
    id: 'common.previous',
    defaultMessage: 'Previous',
  },
  atleastOneAPMsg: {
    id: 'atleastOneAPMsg',
    defaultMessage: 'Please create at least one access point',
  },
  atleastOneNetworkSiteMsg: {
    id: 'atleastOneNetworkSiteMsg',
    defaultMessage: 'Please create at least one network site',
  },
  convertNetworkSiteMessage: {
    id: 'convertNetworkSiteMessage',
    defaultMessage:
      'Converting this site/these sites will cause any network devices or links to be deleted. Do you still want to convert?',
  },
  convertSubscriberSiteMessage: {
    id: 'convertSubscriberSiteMessage',
    defaultMessage:
      'Converting this site/these sites will cause any links to be deleted. Do you still want to convert?',
  },
  quantity: {
    id: 'common.quantity',
    defaultMessage: 'Quantity',
  },
  percent: {
    id: 'common.percent',
    defaultMessage: 'Percent',
  },
  throughtput: {
    id: 'common.throughput',
    defaultMessage: 'Throughput',
  },

  /* Equipment */
  band: {
    id: 'equipment.band',
    defaultMessage: 'Band',
  },
  product: {
    id: 'equipment.product',
    defaultMessage: 'Product',
  },
  regulation: {
    id: 'equipment.regulation',
    defaultMessage: 'Regulation',
  },
  interference: {
    id: 'equipment.interference',
    defaultMessage: 'Interference?',
  },
  interferenceSiteholder: {
    id: 'equipment.interferenceSiteholder',
    defaultMessage: 'Set Interference',
  },

  /* Equipment / PMP configs */
  createConfig: {
    id: 'pmpConfigs.create',
    defaultMessage: 'Create New Equipment Configuration',
  },
  deleteConfigs: {
    id: 'equipment.deleteConfigs',
    defaultMessage: 'Delete Equipment Configs',
  },
  deleteSmConfig: {
    id: 'equipment.deleteSmConfig',
    defaultMessage: 'Delete Subscriber Configuration',
  },

  /* Main Frame */
  closeDialog: {
    id: 'mainFrame.closeDialog',
    defaultMessage: 'Close dialog',
  },

  /* Main Menu */
  mapTitle: {
    id: 'mainMenu.mapTitle',
    defaultMessage: 'Show Map',
  },
  mapViewFilter: {
    id: 'mainMenu.mapViewFilter',
    defaultMessage: 'Use Map View to Filter',
  },
  searchHelp: {
    id: 'mainMenu.searchHelp',
    defaultMessage:
      'Search for features by name. Use regular expression patterns for advanced queries.',
  },
  projectTitle: {
    id: 'mainMenu.projectTitle',
    defaultMessage: 'Edit project settings and user permissions',
  },
  networkSitesTitle: {
    id: 'mainMenu.networkSitesTitle',
    defaultMessage: 'View network sites',
  },
  subscriberSitesTitle: {
    id: 'mainMenu.subscriberSitesTitle',
    defaultMessage: 'View subscriber sites',
  },
  ptpLinksTitle: {
    id: 'mainMenu.ptpLinksTitle',
    defaultMessage: 'View PTP links',
  },
  pmpNetworkDeviceTitle: {
    id: 'mainMenu.pmpNetworkDeviceTitle',
    defaultMessage: 'View PMP network devices',
  },
  meshLinksTitle: {
    id: 'mainMenu.meshLinksTitle',
    defaultMessage: 'View Mesh Links',
  },
  smsTitle: {
    id: 'mainMenu.smsTitle',
    defaultMessage: 'View subscriber modules',
  },
  pmpLinksTitle: {
    id: 'mainMenu.pmpLinksTitle',
    defaultMessage: 'View PMP links',
  },
  ptpLinksTitle: {
    id: 'mainMenu.ptpLinksTitle',
    defaultMessage: 'View PTP links',
  },
  viewshedsTitle: {
    id: 'mainMenu.viewshedsTitle',
    defaultMessage: 'View Viewsheds',
  },
  equipmentTitle: {
    id: 'mainMenu.equipmentTitle',
    defaultMessage: 'View PMP network equipment templates',
  },

  tableView: {
    id: 'siteProperties.tableView',
    defaultMessage: 'Show Table View',
  },
  listView: {
    id: 'siteProperties.listView',
    defaultMessage: 'Show List View',
  },

  /* Site panel */
  importSites: {
    id: 'siteProperties.importSites',
    defaultMessage: 'Import Sites',
  },
  maxHeight: {
    id: 'siteProperties.maxHeight',
    defaultMessage: 'Maximum Height',
  },
  latitude: {
    id: 'siteProperties.latitude',
    defaultMessage: 'Latitude',
  },
  longitude: {
    id: 'siteProperties.longitude',
    defaultMessage: 'Longitude',
  },
  nodeType: {
    id: 'siteProperties.nodeType',
    defaultMessage: 'Node Type',
  },
  closePanel: {
    id: 'siteProperties.closePanel',
    defaultMessage: 'Close panel',
  },
  createSiteCoords: {
    id: 'siteProperties.createSiteCoords',
    defaultMessage: 'Create site by entering coordinates',
  },
  duplicateSelectedItems: {
    id: 'duplicateSelectedItems',
    defaultMessage: 'Duplicate selected items',
  },
  deleteSelectedItems: {
    id: 'siteProperties.deleteSelectedItems',
    defaultMessage: 'Delete selected items',
  },
  deleteSites: {
    id: 'siteProperties.deleteSites',
    defaultMessage: 'Delete sites',
  },

  /* Import sites CSV */
  importNetworkSites: {
    id: 'importCSV.networkSites',
    defaultMessage: 'Import Network Sites',
  },
  importSubscriberSites: {
    id: 'importCSV.subscriberSites',
    defaultMessage: 'Import Subscriber Sites',
  },
  chooseFile: {
    id: 'importCSV.chooseFile',
    defaultMessage: 'Choose File',
  },
  chooseFileDescr: {
    id: 'importCSV.chooseFileDescr',
    defaultMessage: `Please select a CSV, KML or KMZ file containing the site information.
      Click <a> here </a> to download an example of the file format`,
  },
  importErrorMessageNetwork: {
    id: 'importCSV.errorMessageNetwork',
    defaultMessage: `The CSV file must contain Name, Latitude and Longitude columns. Max Height and Description may be empty. Valid Node Type values are empty, "DN" or "POP". A sample file is available
    <a>here</a>.`,
  },
  importErrorMessageSubscriber: {
    id: 'importCSV.errorMessageSubscriber',
    defaultMessage: `The CSV file must contain Name, Latitude and Longitude columns. Max Height and Description values may be empty. A sample file is available
    <a>here</a>.`,
  },
  emptyOrCorruptFile: {
    id: 'emptyOrCorruptFile',
    defaultMessage: 'The data file provided is either empty or corrupt.',
  },
  verifyFile: {
    id: 'importCSV.verify',
    defaultMessage: 'Verify',
  },
  verifyFileDescription: {
    id: 'importCSV.verifyFileDescription',
    defaultMessage: `Check that the site data contains no errors before creating the
    sites in your project`,
  },
  createSites: {
    id: 'importCSV.createSites',
    defaultMessage: 'Create Sites',
  },
  totalSites: {
    id: 'importCSV.totalSites',
    defaultMessage: 'Total no of sites',
  },
  errorSites: {
    id: 'importCSV.errorSites',
    defaultMessage: 'Sites with errors',
  },
  warningSites: {
    id: 'importCSV.warningSites',
    defaultMessage: 'Sites with warnings',
  },
  warningTruncate: {
    id: 'importCSV.warningTruncate',
    defaultMessage: 'Fields truncated to maximum length',
  },
  errorFieldMsg: {
    id: 'importCSV.errorCellMsg',
    defaultMessage: 'Please correct the values in the red cells',
  },
  nearbySiteMsg: {
    id: 'importCSV.nearbySiteMsg',
    defaultMessage:
      'These locations are less than 1m away from an existing site',
  },
  siteWarningMsg: {
    id: 'importCSV.siteWarningMsg',
    defaultMessage:
      'These locations are less than 1m away from an existing site',
  },
  heightUnits: {
    id: 'importCSV.heightUnits',
    defaultMessage: 'Height Unit',
  },
  fileHeaderMsg: {
    id: 'importCSV.fileHeaderMsg',
    defaultMessage: 'File includes header row?',
  },

  /* Link Panel */
  linkDescription: {
    id: 'linkPanel.description',
    defaultMessage: 'Description',
  },
  linkConfiguration: {
    id: 'linkPanel.configuration',
    defaultMessage: 'Configuration',
  },
  linkEquipment: {
    id: 'linkPanel.equipment',
    defaultMessage: 'Equipment',
  },
  linkConfigurationPerEnd: {
    id: 'linkPanel.configurationPerEnd',
    defaultMessage: 'Per End Configuration',
  },
  linkProperties: {
    id: 'linkPanel.properties',
    defaultMessage: 'Properties',
  },
  apPanelOffsetError: {
    id: 'apPanel.offsetError',
    defaultMessage: 'Limited to 100m by Ethernet cable length',
  },
  apPowerEirp: {
    id: 'apPanel.eirp',
    defaultMessage: 'EIRP',
  },
  apPowerPower: {
    id: 'apPanel.power',
    defaultMessage: 'Power',
  },
  apPowerReceiveTargetLevel: {
    id: 'apPanel.receive_target_level',
    defaultMessage: 'SM Receive Target Level',
  },
  saveChanges: {
    id: 'saveChanges',
    defaultMessage: 'Save changes to update the values',
  },
  saveChangesCalcTilt: {
    id: 'saveCaclTiltChanges',
    defaultMessage: 'Save changes to run Calc Tilt',
  },
  applyCalcTilt: {
    id: 'applyCalcTilt',
    defaultMessage: 'Apply Tilt',
  },
  calcTilt: {
    id: 'calcTilt',
    defaultMessage: 'Calc Tilt',
  },
  saveChangesPanel: {
    id: 'saveChangesPanel',
    defaultMessage: 'Save changes to update the {panelName}',
  },
  calculatingResults: {
    id: 'calculatingResults',
    defaultMessage: 'Calculating...',
  },
  /* Ap performance */
  apPanelPerformance: {
    id: 'apPerf.performance',
    defaultMessage: 'Performance Summary',
  },
  apPanelPerformanceDetails: {
    id: 'apPerf.PerformanceDetails',
    defaultMessage: 'Performance Details',
  },
  floodTestTitle: {
    id: 'apPerf.floodTestTitle',
    defaultMessage: 'MU-MIMO Flood Test Grouping',
  },
  muimoReportTitle: {
    id: 'apPerf.muimoReportTitle',
    defaultMessage: 'MU-MIMO Report',
  },
  floodTestGroupUpLink: {
    id: 'apPerf.floodTestGroupUpLink',
    defaultMessage: 'Uplink',
  },
  floodTestGroupDownLink: {
    id: 'apPerf.floodTestGroupDownLink',
    defaultMessage: 'Downlink',
  },
  floodTestMaxMuxGain: {
    id: 'apPerf.floodTestMaxMuxGain',
    defaultMessage: 'Maximum Spatial Multiplexing Gain',
  },
  floodTestSummaryDownLink: {
    id: 'apPerf.floodTestSummaryDownLink',
    defaultMessage: 'Performance Summary DL',
  },
  floodTestSummaryUpLink: {
    id: 'apPerf.floodTestSummaryUpLink',
    defaultMessage: 'Performance Summary UL',
  },
  floodTestLowPriorityVC: {
    id: 'apPerf.floodTestLowPriorityVC',
    defaultMessage: `Flood test for Low Priority VC's only`,
  },
  floodTestReport: {
    id: 'apPerf.floodTestReport',
    defaultMessage: 'Flood Test Report',
  },
  mumimoReport: {
    id: 'apPerf.mumimoReport',
    defaultMessage: 'Mu-MIMO Report',
  },
  apApInterference: {
    id: 'apPanel.apInterference',
    defaultMessage: 'ND Interference?',
  },
  apLinksToSM: {
    id: 'apPanel.apLinksToSM',
    defaultMessage: 'Links to Subscriber Modules',
  },
  apMeshLinks: {
    id: 'apPanel.meshLinks',
    defaultMessage: 'Mesh Links',
  },
  apLinksToSMError: {
    id: 'apPanel.apLinksToSMError',
    defaultMessage: 'Error in getting subsriber modules',
  },
  apLinksToSMRegistrationLimitError: {
    id: 'apPanel.apLinksToSMRegistrationLimitError',
    defaultMessage:
      'The total number of subscribers ({totalNumOfSubscribers}) is higher than can be supported by this access point ({numOfSupportedSubscribers})',
  },
  apSmInterference: {
    id: 'apPanel.smInterference',
    defaultMessage: 'Default SM Interference?',
  },

  /* PMP template panel */
  deleteTemplates: {
    id: 'template.deleteTemplates',
    defaultMessage: 'Delete templates',
  },

  /* Filtered list */
  filter: {
    id: 'filteredList.filter',
    defaultMessage: 'Filter...',
  },
  clearFilter: {
    id: 'filteredList.clearFilter',
    defaultMessage: 'Clear filter',
  },
  clearSearch: {
    id: 'filteredList.clearSearch',
    defaultMessage: 'Clear search',
  },
  clearSelection: {
    id: 'common.clearSelection',
    defaultMessage: 'Clear Selection',
  },
  selectItem: {
    id: 'filteredList.selectItem',
    defaultMessage: 'Select item',
  },
  selectAll: {
    id: 'filteredList.selectAll',
    defaultMessage: 'Select all',
  },
  selectNone: {
    id: 'filteredList.selectNone',
    defaultMessage: 'Select none',
  },
  selectInvert: {
    id: 'filteredList.selectInvert',
    defaultMessage: 'Invert selection',
  },
  showInMap: {
    id: 'filteredList.showInMap',
    defaultMessage: 'Show in map',
  },
  mapFilter: {
    id: 'filteredList.mapFilter',
    defaultMessage: 'Use map to filter list',
  },
  mapFilterList: {
    id: 'filteredList.mapFilterList',
    defaultMessage: 'Use map to filter list',
  },
  noItems: {
    id: 'filteredList.noItems',
    defaultMessage: 'No Items',
  },
  /* Breadcrumbs */

  ptpLinks: {
    id: 'breadcrumbs.ptpLinks',
    defaultMessage: 'PTP Links',
  },
  pmpEquipment: {
    id: 'breadcrumbs.pmpEquipment',
    defaultMessage: 'PMP Equipment',
  },

  /* Header */
  filterInMap: {
    id: 'filteredList.filterInMap',
    defaultMessage:
      'Only show features that match this filter text in the map. Use regular expressions for advanced pattern matching.',
  },
  filterInMapPlaceHolder: {
    id: 'filteredList.filterInMapPlaceHolder',
    defaultMessage: 'Filter map items by name...',
  },
  layers: {
    id: 'header.layers',
    defaultMessage: 'Layers',
  },
  loginToSave: {
    id: 'header.loginToSave',
    defaultMessage: 'Please login to save the project',
  },
  cambium: {
    id: 'header.cambium',
    defaultMessage: 'Cambium Networks home page',
  },
  help: {
    id: 'header.help',
    defaultMessage: 'Help',
  },
  login: {
    id: 'header.login',
    defaultMessage: 'Login',
  },
  preferences: {
    id: 'header.preferences',
    defaultMessage: 'Preferences',
  },
  preferenceTooltip: {
    id: 'header.preferenceTooltip',
    defaultMessage: 'Set account information and preferences',
  },
  adminTools: {
    id: 'admin.tools',
    defaultMessage: 'Admin Tools',
  },
  adminTooltip: {
    id: 'admin.toolTip',
    defaultMessage: 'Set user information settings tool',
  },
  adminToolUserInfoSettingsTitle: {
    id: 'admin.userInfoSettings.title',
    defaultMessage: 'User Information Settings',
  },
  adminUserInfoSettingsEmail: {
    id: 'admin.userInfoSettings.email',
    defaultMessage: 'Email',
  },
  adminUserInfoSettingsFind: {
    id: 'admin.userInfoSettings.find',
    defaultMessage: 'Find',
  },
  adminUserInfoSettingsEnabled: {
    id: 'admin.userInfoSettings.enabled',
    defaultMessage: 'Enabled',
  },
  adminUserInfoSettingsAllowAnp: {
    id: 'admin.userInfoSettings.EnableAnp',
    defaultMessage: 'Enable ANP',
  },
  adminUserInfoSettingsProjects: {
    id: 'admin.userInfoSettings.projects',
    defaultMessage: 'Projects',
  },
  adminUserInfoSettingsNetworkSites: {
    id: 'admin.userInfoSettings.networkSites',
    defaultMessage: 'Network Sites',
  },
  adminUserInfoSettingsSubscriberSites: {
    id: 'admin.userInfoSettings.subscriberSites',
    defaultMessage: 'Subscriber Sites',
  },
  adminUserInfoSettingsPMPNetworkDevices: {
    id: 'admin.userInfoSettings.pmpNetworkDevices',
    defaultMessage: 'PMP Network Devices',
  },
  adminUserInfoSettingsPMPLinks: {
    id: 'admin.userInfoSettings.pmpLinks',
    defaultMessage: 'PMP Links',
  },
  adminUserInfoSettingsCancel: {
    id: 'admin.userInfoSettings.cancel',
    defaultMessage: 'Cancel',
  },
  adminUserInfoSettingsUpdate: {
    id: 'admin.userInfoSettings.update',
    defaultMessage: 'Update',
  },
  adminToolsRunQuery: {
    id: 'admin.runQuery',
    defaultMessage: 'Run Query',
  },
  adminUserInfoSettingsRestoreDefault: {
    id: 'admin.userInfoSettings.restoreDefault',
    defaultMessage: 'Restore Default',
  },
  adminUserInfoSettingsLidarProjects: {
    id: 'admin.userInfoSettings.lidarProjects',
    defaultMessage: 'Default to Lidar Profiles?',
  },
  adminUserInfoSettingsLidarLength: {
    id: 'admin.userInfoSettings.lidarLength',
    defaultMessage: 'Lidar Profile Length Limit',
  },
  adminToolsError: {
    id: 'admin.toolsError',
    defaultMessage: 'Error in updating the Admin Tools',
  },
  logout: {
    id: 'header.logout',
    defaultMessage: 'Logout',
  },
  releaseNotes: {
    id: 'header.releaseNotes',
    defaultMessage: 'Release Notes',
  },
  userGuide: {
    id: 'header.userGuide',
    defaultMessage: 'User Guide',
  },
  support: {
    id: 'header.support',
    defaultMessage: 'Support',
  },
  about: {
    id: 'header.about',
    defaultMessage: 'About',
  },
  aboutLINKPlanner: {
    id: 'header.aboutLINKPlanner',
    defaultMessage: 'About LINKPlanner',
  },

  /* User Preferences */

  accountAndPrefs: {
    id: 'preferences.accountAndPrefs',
    defaultMessage: 'Account and Preferences',
  },
  personalInformation: {
    id: 'preferences.personalInfo',
    defaultMessage: 'Personal Information',
  },
  language: {
    id: 'preferences.language',
    defaultMessage: 'Language',
  },
  contact: {
    id: 'preferences.contact',
    defaultMessage: 'Phone',
  },
  height: {
    id: 'preferences.height',
    defaultMessage: 'Height',
  },
  length: {
    id: 'preferences.length',
    defaultMessage: 'Length',
  },
  units: {
    id: 'preferences.units',
    defaultMessage: 'Units',
  },
  unitsAndFormatting: {
    id: 'preferences.unitsAndFormatting',
    defaultMessage: 'Units and Formatting',
  },
  macAddress: {
    id: 'preferences.macAddress',
    defaultMessage: 'Mac Address',
  },
  latitudeLongitude: {
    id: 'preferences.latitudeLongitude',
    defaultMessage: 'Latitude/Longitude',
  },
  latlngDegree: {
    id: 'preferences.latlngdegree',
    defaultMessage: 'deg (ddd.dddddP)',
  },
  latlngDegreeMin: {
    id: 'preferences.latlngDegreeMin',
    defaultMessage: 'deg:min (ddd:mm:mmmP)',
  },
  latlngDegreeMinSec: {
    id: 'preferences.latlngDegreeMinSec',
    defaultMessage: 'deg:min:sec (ddd:mm:ss.ssP)',
  },
  latlngDegreeSigned: {
    id: 'preferences.latlngDegreeSigned',
    defaultMessage: 'signed deg (+/-ddd.ddddd)',
  },
  languageUSEnglish: {
    id: 'preferences.USEnglish',
    defaultMessage: 'English (US)',
  },
  languageUKEnglish: {
    id: 'preferences.UKEnglish',
    defaultMessage: 'English (UK)',
  },
  preferencesError: {
    id: 'preferencesError',
    defaultMessage: 'Error in updating the preferences',
  },

  /* Terms & conditions  */
  tncError: {
    id: 'tncError',
    defaultMessage: 'Error in accepting terms and conditions',
  },
  promotionalMails: {
    id: 'promotionalMails',
    defaultMessage:
      'Uncheck this box if you consent to the use of your information provided for marketing purposes to support the functionalities of LINKPlanner',
  },
  termsAndConditions: {
    id: 'termsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  linkplannerTerms: {
    id: 'linkplannerTerms',
    defaultMessage: 'LINKPlanner Terms & Conditions',
  },
  privacyPolicy: {
    id: 'privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  /* Map */
  zoomFit: {
    id: 'map.zoomFit',
    defaultMessage: 'Zoom to fit the entire network',
  },
  selectItems: {
    id: 'map.selectItems',
    defaultMessage: 'Select items',
  },
  moveSite: {
    id: 'map.moveSite',
    defaultMessage: 'Move Site',
  },
  confirmSiteMove: {
    id: 'map.confirmSiteMove',
    defaultMessage: 'Move {name} to {lat}, {lng}?',
  },
  useArrowKeys: {
    id: 'map.useArrowKeys',
    defaultMessage:
      'Use the up/down arrow to cycle through the features at this location',
  },
  clickForNetworkSite: {
    id: 'map.clickForNetworkSite',
    defaultMessage: 'Click on the map to create a network site',
  },
  ptpModeHelpMessage: {
    id: 'map.ptpModeHelpMessage',
    defaultMessage:
      'Select 2 locations to create a new PTP link. Click an existing network site or anywhere on the map.',
  },
  afcModeHelpMessage: {
    id: 'map.afcModeHelpMessage',
    defaultMessage:
      'Click on the map to select a location or enter coordinates and submit for the Automated Frequency Control (AFC) look-up.',
  },
  afcChartErrorMessage: {
    id: 'map.afcChartErrorMessage',
    defaultMessage:
      'No data found for the selected location. This feature is only available over land in the US and Canada.',
  },
  afcInvalidLocationError: {
    id: 'map.afcInvalidLocationError',
    defaultMessage:
      'Data is not available for this location. Please try a different location',
  },
  afcTitle: {
    id: 'map.afcTitle',
    defaultMessage: '6 GHz Automated Frequency Control (AFC) Look-up',
  },
  afcResultsTitle: {
    id: 'map.afcResultsTitle',
    defaultMessage: '6 GHz Frequency Availability (AFC)',
  },
  clickForSubscriberSite: {
    id: 'map.clickForSubscriberSite',
    defaultMessage: 'Click on the map to create a subscriber site',
  },
  clickForAccessPoint: {
    id: 'map.clickForAccessPoint',
    defaultMessage:
      'Click an existing network site or anywhere on the map to create a new PMP network device',
  },
  clickForPMPLink: {
    id: 'map.clickForPMPLink',
    defaultMessage:
      'Click an existing subscriber site or anywhere in a sector to create a new PMP link',
  },
  clickToClose: {
    id: 'map.clickToClose',
    defaultMessage: 'Click to close',
  },
  clickForViewsheds: {
    id: 'map.clickForViewsheds',
    defaultMessage:
      'Click on the map to indicate where you would like to calculate viewsheds',
  },
  networkSiteMode: {
    id: 'mapToolbar.networkSiteMode',
    defaultMessage: 'Network Site Mode',
  },
  subscriberSiteMode: {
    id: 'mapToolbar.subscriberSiteMode',
    defaultMessage: 'Subscriber Site Mode',
  },
  ptpLinkMode: {
    id: 'mapToolbar.ptpLinkMode',
    defaultMessage: 'PTP Link Mode',
  },
  siteNameTemplate: {
    id: 'mapToolbar.siteNameTemplate',
    defaultMessage: 'Site Name Template',
  },
  siteName: {
    id: 'mapToolbar.siteName',
    defaultMessage: 'Site Name',
  },
  localCoordinates: {
    id: 'mapToolbar.localCoords',
    defaultMessage: 'Local Coordinates',
  },
  coordinates: {
    id: 'mapToolbar.coords',
    defaultMessage: 'Coordinates',
  },
  remoteCoordinates: {
    id: 'mapToolbar.remoteCoords',
    defaultMessage: 'Remote Coordinates',
  },
  showHelpPopups: {
    id: 'mapToolbar.showHelpPopups',
    defaultMessage: 'Show detailed help pop-up messages?',
  },
  hideHelp: {
    id: 'mapToolbar.hideHelp',
    defaultMessage: 'Hide these messages',
  },

  /* Project details */
  nameSiteholder: {
    id: 'projectDetails.nameSiteholder',
    defaultMessage: 'Name...',
  },
  customerName: {
    id: 'projectDetails.customerName',
    defaultMessage: 'Customer Name',
  },
  companyName: {
    id: 'projectDetails.companyName',
    defaultMessage: 'Company Name',
  },
  address: {
    id: 'projectDetails.address',
    defaultMessage: 'Address',
  },
  email: {
    id: 'projectDetails.email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'projectDetails.phone',
    defaultMessage: 'Phone',
  },
  cellPhone: {
    id: 'projectDetails.cellPhone',
    defaultMessage: 'Cellular Phone',
  },
  predictionModel: {
    id: 'projectDetails.predictionModel',
    defaultMessage: 'Prediction Model',
  },
  useClutter: {
    id: 'projectDetails.useClutter',
    defaultMessage: 'Use Clutter',
  },
  clutterHeights: {
    id: 'projectDetails.clutterHeights',
    defaultMessage: 'Clutter Heights',
  },
  editClutterData: {
    id: 'projectDetails.editClutterData',
    defaultMessage: 'Edit Clutter Data',
  },

  /* Access Points */
  pmpNetworkDevice: {
    id: 'accessPoints.pmpNetworkDevice',
    defaultMessage: 'PMP network device',
  },
  createAccessPoint: {
    id: 'accessPoints.createAccessPoint',
    defaultMessage: 'Create PMP network devices',
  },
  saveAccessPoint: {
    id: 'accessPoints.saveAccessPoint',
    defaultMessage: 'Save PMP network device',
  },
  createAPError: {
    id: 'createAPError',
    defaultMessage: 'Error in creating the network devices',
  },
  networkDevices: {
    id: 'networkDevices',
    defaultMessage: 'Network Devices',
  },
  deleteAccessPoints: {
    id: 'template.deleteAccessPoints',
    defaultMessage: 'Delete PMP network devices',
  },
  deletePTPLinks: {
    id: 'template.deletePTPLinks',
    defaultMessage: 'Delete PTP Links',
  },
  smRangeInfoForKm: {
    id: 'smRangeInfoForKm',
    defaultMessage: 'Minimum value 0.015 km',
  },
  smRangeInfoForMi: {
    id: 'smRangeInfoForMi',
    defaultMessage: 'Minimum value 0.009 mi',
  },
  apsPerHub: {
    id: 'accessPoints.APsPerHub',
    defaultMessage: 'Devices Per Hub',
  },
  startingAzimuth: {
    id: 'accessPoints.startingAzimuth',
    defaultMessage: 'Starting Azimuth',
  },
  separationAngle: {
    id: 'accessPoints.separationAngle',
    defaultMessage: 'Separation Angle',
  },
  rangeUnits: {
    id: 'accessPoints.rangeUnits',
    defaultMessage: 'Range Units',
  },
  SMRange: {
    id: 'accessPoints.SMRange',
    defaultMessage: 'SM Range',
  },
  modeledBeamwidth: {
    id: 'accessPoints.modeledBeamwidth',
    defaultMessage: 'Modeled Beamwidth',
  },
  azimuth: {
    id: 'accessPoints.azimuth',
    defaultMessage: 'Azimuth',
  },
  apConfigChoice: {
    id: 'accessPoints.eqConfigChoice',
    defaultMessage: 'Equipment Configuration',
  },
  apConfigChoiceSiteholder: {
    id: 'accessPoints.eqConfigChoiceSiteholder',
    defaultMessage: 'Choose an Equipment Config',
  },
  subscriber: {
    id: 'subscriber.subscriber',
    defaultMessage: 'Subscriber',
  },
  attachSP: {
    id: 'accessPoints.attachSP',
    defaultMessage: 'Attach Subscribers',
  },
  createPTP: {
    id: 'ptp.createPTP',
    defaultMessage: 'Create PTP Links',
  },
  ptpFeederLossWarningMessage: {
    id: 'ptpFeederLossWarningMessage',
    defaultMessage:
      'Estimates do not include any performance degradations caused by mismatched components',
  },
  ptpTxFrequencyErrorMessage: {
    id: 'ptpTxFrequencyErrorMessage',
    defaultMessage:
      'Error: Unable to determine the frequency pair. Please select an alternative frequency.',
  },
  spEquipment: {
    id: 'accessPoints.spEquipment',
    defaultMessage: 'Subscriber Equipment Configuration',
  },
  attachSMError: {
    id: 'attachSMError',
    defaultMessage: 'Error in attaching subscriber to ND',
  },
  showSubscribers: {
    id: 'showSubscribers',
    defaultMessage: 'Show Subscribers',
  },
  subscriberModules: {
    id: 'subscriberModules',
    defaultMessage: "SM's of {apName}",
  },
  deleteSubscriberModules: {
    id: 'template.deleteSubscriberModules',
    defaultMessage: 'Delete subscriber modules',
  },
  deletePMPLinks: {
    id: 'template.deletePMPLinks',
    defaultMessage: 'Delete PMP Links',
  },
  smConfigChoice: {
    id: 'sm.eqConfigChoice',
    defaultMessage: 'Equipment Config',
  },
  smConfigChoiceSiteholder: {
    id: 'sm.eqConfigChoiceSiteholder',
    defaultMessage: 'Choose an Equipment Config',
  },
  smsPerDLModulation: {
    id: 'ap.smsPerDLModulation',
    defaultMessage: 'SMs per DL Modulation',
  },
  smsPerULModulation: {
    id: 'ap.smsPerULModulation',
    defaultMessage: 'SMs per UL Modulation',
  },
  ccsPerDLModulation: {
    id: 'ap.ccsPerDLModulation',
    defaultMessage: 'CCs per DL Modulation',
  },
  ccsPerULModulation: {
    id: 'ap.ccsPerULModulation',
    defaultMessage: 'CCs per UL Modulation',
  },
  apMeanThroughput: {
    id: 'ap.meanThroughput',
    defaultMessage: 'Total Mean Predicted Throughput',
  },
  noSMsInRange: {
    id: 'ap.noSMsInRange',
    defaultMessage: 'No Subscriber sites within range',
  },
  dropdownPlaceHolderTxt: {
    id: 'dropdownPlaceHolderTxt',
    defaultMessage: 'Copy from existing...',
  },
  copyFromConfirmation: {
    id: 'copyFromConfirmation',
    defaultMessage:
      'Are you sure you want to update the {qtyAndKind} with the configuration from',
  },
  copyFromHelp: {
    id: 'copyFromHelp',
    defaultMessage:
      'Select an existing {kind} to copy the configuration from and apply it to your current selection',
  },

  //Charts labels
  rangeLabel: {
    id: 'rangeLabel',
    defaultMessage: 'Range on path ({unit})',
  },
  range: {
    id: 'range',
    defaultMessage: 'Range',
  },
  distanceToFresnel: {
    id: 'distanceToFresnel',
    defaultMessage: 'Distance to Fresnel Zone',
  },
  heightLabel: {
    id: 'heightLabel',
    defaultMessage: 'Height above sea level ({unit})',
  },
  sightLine: {
    id: 'sightLine',
    defaultMessage: 'Sight Line',
  },
  obstruction: {
    id: 'obstruction',
    defaultMessage: 'Obstruction',
  },
  worstEarth: {
    id: 'worstEarth',
    defaultMessage: 'Worst Earth',
  },
  fresnel: {
    id: 'fresnel',
    defaultMessage: 'Fresnel',
  },
  groundHeight: {
    id: 'groundHeight',
    defaultMessage: 'Ground Height',
  },
  invalidData: {
    id: 'invalidData',
    defaultMessage: 'Invalid Data',
  },
  chartError: {
    id: 'chartError',
    defaultMessage: 'Unable to display profile chart',
  },

  //Performance summary
  performanceToAP: {
    id: 'performanceToAp',
    defaultMessage: 'Performance to ND',
  },
  performanceTo: {
    id: 'performanceTo',
    defaultMessage: 'Performance to {name}',
  },
  performanceToSM: {
    id: 'performanceToSM',
    defaultMessage: 'Performance to SM',
  },
  performanceToDevices: {
    id: 'performanceToDevices',
    defaultMessage: 'Performance to Devices',
  },
  linkSummary: {
    id: 'linkSummary',
    defaultMessage: 'Link Summary',
  },
  operatingConditions: {
    id: 'operatingConditions',
    defaultMessage: 'Operating Conditions',
  },
  inValidPercentErr: {
    id: 'inValidPercentErr',
    defaultMessage: 'Please enter valid percentage',
  },

  /* Custom Antenna */

  minimumGainsMsg: {
    id: 'minimumGainsMsg',
    defaultMessage: 'You must specify the antenna gain for at least one band',
  },
  ptpAntennaNoteMsg: {
    id: 'ptpAntennaNoteMsg',
    defaultMessage: `Note:ePMP and PTP450 product families only support dual polarization antennas,
    cnReach requires single polarization.`,
  },
  smAntennaNoteMsg: {
    id: 'smAntennaNoteMsg',
    defaultMessage: `Note:cnReach requires single polarization antennas,
    all other products require dual polarization.`,
  },
  antennaFormCommonMsg: {
    id: 'antennaFormCommonMsg',
    defaultMessage: 'Certain products have a maximum antenna gain limit',
  },
  antennaBuiltInEditMsg: {
    id: 'antennaBuiltInEditMsg',
    defaultMessage:
      'You cannot modify built-in antennas, but you can create a copy.',
  },
  builtInEditConfirmPrompt: {
    id: 'builtInEditConfirmPrompt',
    defaultMessage: 'Would you like to copy {label}?',
  },

  /* Best Server */
  bestServerTitle: {
    id: 'mainMenu.bestServerTitle',
    defaultMessage: 'View Best Server',
  },
  bestServer: {
    id: 'common.bestServer',
    defaultMessage: 'Best Server',
  },

  /* Best Server */
  terragraphTitle: {
    id: 'mainMenu.terragraphTitle',
    defaultMessage: 'View Terragraph Planner',
  },
  terragraph: {
    id: 'common.terragraph',
    defaultMessage: 'Terragraph Planner',
  },
  resetTerragraph: {
    id: 'common.resetTerragraph',
    defaultMessage: 'Reset Terragraph',
  },
  resetTerragraphConfirm: {
    id: 'common.resetTerragraphConfirm',
    defaultMessage:
      'This action will delete the terragraph planner configuration, are you sure?',
  },

  /* Viewsheds */
  highResViewshed: {
    id: 'highResViewshed',
    defaultMessage: 'Create High Resolution Viewshed?',
  },
  siteMaxHeight: {
    id: 'siteMaxHeight',
    defaultMessage: 'Default Maximum Height at Site',
  },
  useSiteMaxHeight: {
    id: 'useSiteMaximumHeight',
    defaultMessage: 'Use Site Maximum Height?',
  },
  useTowerHeight: {
    id: 'useTowerHeight',
    defaultMessage: 'Use Tower Height?',
  },
  useTowerHeightAboveGround: {
    id: 'useTowerHeightAboveGround',
    defaultMessage: 'Use Tower Height Above Ground?',
  },
  useTowerHeightAboveClutter: {
    id: 'useTowerHeightAboveClutter',
    defaultMessage: 'Use Tower Height Above Clutter?',
  },
  towerHeight: {
    id: 'towerHeight',
    defaultMessage: 'Tower Height',
  },
  radius: {
    id: 'radius',
    defaultMessage: 'Radius',
  },
  opticalLos: {
    id: 'opticalLos',
    defaultMessage: 'Optical LOS',
  },
  radioLos: {
    id: 'radioLos',
    defaultMessage: 'Radio LOS',
  },
  useHeightAboveGround: {
    id: 'useHeightAboveGround',
    defaultMessage: 'Use Height Above Ground?',
  },
  useHeightAboveClutter: {
    id: 'useHeightAboveClutter',
    defaultMessage: 'Use Height Above Clutter?',
  },
  useMinHeightAboveClutter: {
    id: 'useMinHeightAboveClutter',
    defaultMessage: 'Use Min Height Above Clutter?',
  },
  heightAboveGround: {
    id: 'heightAboveGround',
    defaultMessage: 'Height Above Ground',
  },
  heightAboveClutter: {
    id: 'heightAboveClutter',
    defaultMessage: 'Height Above Clutter',
  },
  installationReport: {
    id: 'installationReport',
    defaultMessage: 'Installation Report PDF',
  },
  salesReport: {
    id: 'salesReport',
    defaultMessage: 'Proposal Report PDF',
  },

  mapMeasure: {
    id: 'common.mapMeasure',
    defaultMessage: 'Measure',
  },
  networkDeviceScale: {
    id: 'common.networkDeviceScale',
    defaultMessage: 'Scale PMP ND Sector Display',
  },

  // mesh link
  attachMeshLink: {
    id: 'meshLinks.createMeshLink',
    defaultMessage: 'Create Mesh Links',
  },
  attachMeshLinkSite: {
    id: 'meshLinks.meshLinkSite',
    defaultMessage: 'Site',
  },
  meshLinks: {
    id: 'meshLinks',
    defaultMessage: 'Mesh Links',
  },
  meshLinkDetailsEquipmentSummary: {
    id: 'meshLinkDetails.equipmentSummary',
    defaultMessage: 'Equipment Summary',
  },
  addMeshLinkSelectedDN: {
    id: 'addMeshlink.selectedDN',
    defaultMessage: 'Selected DN',
  },
  addMeshLinkDNSInRange: {
    id: 'addMeshlink.DNSInRange',
    defaultMessage: 'DNs in Range',
  },
  deleteMeshLinks: {
    id: 'delete.meshLinks',
    defaultMessage: 'Delete Mesh Links',
  },
  attachMeshLinkSelectOneOrMoreDNs: {
    id: 'attachMeshLink.selectOneOrMoreDNs',
    defaultMessage: 'Select one or more DNs.',
  },
  attachMeshLinkDNSupportMaxMeshLinks: {
    id: 'attachMeshLink.DNSupportMaxMeshLinks',
    defaultMessage: 'The DN Supports a maximum of 4 mesh links.',
  },
  attachMeshLinkNumOfDNsSelected: {
    id: 'attachMeshLink.numOfDNsSelected',
    defaultMessage: 'Number of DNs selected:',
  },

  // terragraph planner
  minValueError: {
    id: 'min0Error',
    defaultMessage: 'Value cannot be less than {value}',
  },
  maxValueError: {
    id: 'maxValueError',
    defaultMessage: 'Value cannot be greater than {value}',
  },

  minBudgetValueError: {
    id: 'minBudgetValueError',
    defaultMessage: 'This budget may be too low to connect all CNs',
  },

  maxBudgetValueError: {
    id: 'maxBudgetValueError',
    defaultMessage: 'Excessive budget may add unnecessary extra POP/DN',
  },

  Distribution: {
    id: 'Distribution',
    defaultMessage: 'Distribution Network',
  },
  Mesh: {
    id: 'Mesh',
    defaultMessage: 'Mesh Only Network',
  },
  networkPlan: {
    id: 'NetworkPlan',
    defaultMessage: 'Network Plan',
  },
  inputsData: {
    id: 'inputsData',
    defaultMessage: 'Inputs / Data',
  },
  equipmentConfiguration: {
    id: 'equipmentConfiguration',
    defaultMessage: 'Equipment Configuration',
  },
  financials: {
    id: 'financials',
    defaultMessage: 'Financials',
  },
  createNetworkDesign: {
    id: 'createNetworkDesign',
    defaultMessage: 'Create Network Design',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
  uniformDemandModel: {
    id: 'uniformDemandModel',
    defaultMessage: 'Uniform Demand Model',
  },
  costModel: {
    id: 'costModel',
    defaultMessage: 'Cost Model',
  },
  popHeight: {
    id: 'popHeight',
    defaultMessage: 'POP Height',
  },
  dnHeight: {
    id: 'dnHeight',
    defaultMessage: 'DN Height',
  },
  cnHeight: {
    id: 'cnHeight',
    defaultMessage: 'CN Height',
  },
  maxLosDistance: {
    id: 'maxLosDistance',
    defaultMessage: 'Max LOS Distance',
  },
  minMcsofMeshLink: {
    id: 'minMcsofMeshLink',
    defaultMessage: 'Min MCS of Mesh Link',
  },
  minMcsofAccessLink: {
    id: 'minMcsofAccessLink',
    defaultMessage: 'Min MCS of Access Link',
  },
  boundaryPolygon: {
    id: 'boundaryPolygon',
    defaultMessage: 'Boundary Polygon',
  },
  distributionNetworkHelpText: {
    id: 'distributionNetworkHelpText',
    defaultMessage: 'End to End Network with CN devices.',
  },
  meshOnlyNetworkHelpText: {
    id: 'meshOnlyNetworkHelpText',
    defaultMessage: 'Backhaul network design only, without CN devices.',
  },
  meshOnlyNetworkCostModelHelpText: {
    id: 'meshOnlyNetworkCostModelHelpText',
    defaultMessage:
      'Backhaul network design only, without CN devices. This model assumes POP/DN locations as CN locations.',
  },
  uniformDemanModelHelpText: {
    id: 'uniformDemanModelHelpText',
    defaultMessage:
      'This model uses a grid of imaginary demand locations to simulate and design the mesh network.',
  },
  costModelHelpText: {
    id: 'costModelHelpText',
    defaultMessage: 'This model assumes POP/DNs as CNs locations.',
  },
  boundaryPolygonHelpText: {
    id: 'boundaryPolygonHelpText',
    defaultMessage:
      'The area that planning will be restricted to. The file can contain only one polygon. File type: KML or KMZ',
  },
  loadDsm: {
    id: 'loadDsm',
    defaultMessage: 'Load DSM',
  },
  loadDsmHelpText: {
    id: 'loadDsmHelpText',
    defaultMessage:
      'Checks if LiDAR data is available for the project area or not.',
  },
  popSitesHelpText: {
    id: 'popSitesHelpText',
    defaultMessage: 'Summarizes the number of candidate POP locations.',
  },
  dnSitesHelpText: {
    id: 'dnSitesHelpText',
    defaultMessage: 'Summarizes the number of candidate DN locations.',
  },
  cnSitesHelpText: {
    id: 'cnSitesHelpText',
    defaultMessage: 'Summarizes the number of candidate CN locations.',
  },
  popHeightHelpText: {
    id: 'popHeightHelpText',
    defaultMessage:
      'The default height, that points of presence (POPs) will be mounted on poles. This height is above the surface (ground height+obstruction height).',
  },
  dnHeightHelpText: {
    id: 'dnHeightHelpText',
    defaultMessage:
      'The default height, that distribution nodes (DNs) will be mounted on poles.This height is above the surface (ground height+obstruction height).',
  },
  cnHeightHelpText: {
    id: 'cnHeightHelpText',
    defaultMessage:
      'The default height, that client nodes (CNs) will be mounted on poles. This height is above the surface (ground height+obstruction height).',
  },
  useSiteMaxHeightHelpText: {
    id: 'useSiteMaxHeightHelpText',
    defaultMessage: 'Select this option to use site maximum height provided.',
  },
  useHeightAboveSurfaceHelpText: {
    id: 'useHeightAboveSurfaceHelpText',
    defaultMessage:
      'Select this option to use fixed height above the surface (ground height + obstruction height)',
  },
  maxLosHelpText: {
    id: 'maxLosHelpText',
    defaultMessage:
      'Maximum distance for viable communication between two sites.',
  },
  minMcsMeshHelpText: {
    id: 'minMcsMeshHelpText',
    defaultMessage:
      'Minimum MCS used to determine the maximum line-of-sight distance for backhaul links in the LOS candidate graph. This distance is computed to be the point at which the link capacity goes to 0 (ignoring interference) based on the provided radio equipment specifications. Increasing the minimum MCS shortens that distance.',
  },
  minMcsAccessHelpText: {
    id: 'minMcsAccessHelpText',
    defaultMessage:
      'Minimum MCS used to determine the maximum line-of-sight distance for access links in the LOS candidate graph. This distance is computed to be the point at which the link capacity goes to 0 (ignoring interference) based on the provided radio equipment specifications. Increasing the minimum MCS shortens that distance.',
  },
  channelHelpText: {
    id: 'channelHelpText',
    defaultMessage: 'Choose the 60 GHz channel to be used in the analysis.',
  },
  countryHelpText: {
    id: 'countryHelpText',
    defaultMessage:
      'Based on the country selection, appropriate regulatory limits are applied to the selected products.',
  },
  popDnHelpText: {
    id: 'popDnHelpText',
    defaultMessage: 'Product that can be used as POP/DN.',
  },
  cnHelpText: {
    id: 'cnHelpText',
    defaultMessage: 'Select all required products  that can be used as CN.',
  },
  minMcsLevelHelpText: {
    id: 'minMcsLevelHelpText',
    defaultMessage: 'The minimum MCS level required.',
  },
  maxEirpHelpText: {
    id: 'maxEirpHelpText',
    defaultMessage: 'EIRP specified here is a maximum for all products',
  },
  equipmentCapExHelpText: {
    id: 'equipmentCapExHelpText',
    defaultMessage: 'Hardware cost of DN or CN.',
  },
  budgetHelpText: {
    id: 'budgetHelpText',
    defaultMessage:
      'Maximum budget allowed for radio costs (required). The unit must be the same as the cost parameters above. Site costs are excluded from this budget.',
  },
  defaultCnCirHelpText: {
    id: 'defaultCnCirHelpText',
    defaultMessage:
      'Expected committed information rate (CIR) demand pulled from each CN location in Gbps (required).',
  },
  overSubscriptionHelpText: {
    id: 'overSubscriptionHelpText',
    defaultMessage: 'Oversubscription factor. Must be at least 1',
  },
  popCapacityHelpText: {
    id: 'popCapacityHelpText',
    defaultMessage:
      'Expected total bandwidth per POP location in Gbps. When using a POP as a DN the POP Capacity should be at least 3.8 Gbps.',
  },
  linkAvailabilityHelpText: {
    id: 'linkAvailabilityHelpText',
    defaultMessage:
      'The percentage of time in one year that each line-of-sight (LOS) link will be live, such as 95, 99, 99.99, etc.',
  },
  demandRadiusHelpText: {
    id: 'demandRadiusHelpText',
    defaultMessage: 'Maximum coverage of a POP/DN in meters/feet.',
  },
  demandSpacingHelpText: {
    id: 'demandSpacingHelpText',
    defaultMessage:
      'For Uniform Demand Model, spacing between demand points in meters/feet.',
  },
  extraPopsHelpText: {
    id: 'extraPopsHelpText',
    defaultMessage:
      'Maximum number of extra POPs that the tool should propose. Must be greater than zero if there are no POPs among the input sites. To use only POPs from the input sites, enter zero to disable POP suggestions.',
  },
  useAllPopSitesHelpText: {
    id: 'useAllPopSitesHelpText',
    defaultMessage:
      'If Checked, force all POP sites provided in the user input file to be proposed. POPs in the final network plan that do not serve any traffic will not be proposed.',
  },
  numberOfChannelsHelpText: {
    id: 'numberOfChannelsHelpText',
    defaultMessage:
      'The number of channels to use in the network design. If only one channel is allowed, then all links operate on the same channel.',
  },
  maxGuaranteedBwHelpText: {
    id: 'maxGuaranteedBwHelpText',
    defaultMessage:
      'Maximize the minimum guaranteed bandwidth among all connectable demand sites rather than the total network bandwidth.',
  },
  maxNumberOfHopsHelpText: {
    id: 'maxNumberOfHopsHelpText',
    defaultMessage:
      'Maximum number of hops (from POP to edge site of the network) allowed in the network. This constraint is softly enforced, but cannot always be guaranteed.',
  },
  redundancyLevelHelpText: {
    id: 'redundancyLevelHelpText',
    defaultMessage: `The level of redundancy (Legacy Method/None/Low/Medium/High) to be applied. {lineBreak}
    Legacy Method Maximizes redundancy within the budget constraints. {lineBreak}
    None is not redundant to any link or site. {lineBreak}
    Low is resilient to any single link failure. {lineBreak}
    Medium is resilient to any single site (POP or DN) failure. {lineBreak}
    High is resilient to a simultaneous POP and DN failure or 3 simultaneous DN failures. {lineBreak}`,
  },
  cnsCanBeUsedAsDns: {
    id: 'cnsCanBeUsedAsDns',
    defaultMessage: 'CN Sites Can Be Used As DN Sites',
  },
  useHeightAboveSurface: {
    id: 'useHeightAboveSurface',
    defaultMessage: 'Use Height Above Surface?',
  },
  deviceOptionsError: {
    id: 'deviceOptionsError',
    defaultMessage: 'Select one or more antenna options',
  },
  distributionDeviceOptionsError: {
    id: 'distributionDeviceOptionsError',
    defaultMessage:
      'Distribution network cannot have both V3000 High Gain and  V3000 High Gain + Radome',
  },
  deviceRequired: {
    id: 'deviceRequired',
    defaultMessage: '{value} Device Required',
  },
  equipmentCapEx: {
    id: 'equipmentCapEx',
    defaultMessage: 'Equipment CapEx',
  },
  popDn: {
    id: 'popDn',
    defaultMessage: 'POP / DN',
  },
  usd: {
    id: 'usd',
    defaultMessage: 'USD',
  },
  cn: {
    id: 'cn',
    defaultMessage: 'CN',
  },
  defaultCnCir: {
    id: 'defaultCnCir',
    defaultMessage: 'Default CN CIR (Gbps)',
  },
  oversubscription: {
    id: 'oversubscription',
    defaultMessage: 'Oversubscription',
  },
  linkAvailability: {
    id: 'linkAvailability',
    defaultMessage: 'Link Availability (%)',
  },
  popCapacity: {
    id: 'popCapacity',
    defaultMessage: 'Pop Capacity (Gbps)',
  },
  demandRadius: {
    id: 'demandRadius',
    defaultMessage: 'Demand Radius',
  },
  demandSpacing: {
    id: 'demandSpacing',
    defaultMessage: 'Demand Spacing',
  },
  extraPops: {
    id: 'extraPops',
    defaultMessage: 'Extra POPs',
  },
  noOfChannels: {
    id: 'noOfChannels',
    defaultMessage: 'Number Of Channels',
  },
  maxHops: {
    id: 'maxHops',
    defaultMessage: 'Maximum Number of Hops',
  },
  useAllPopSites: {
    id: 'useAllPopSites',
    defaultMessage: 'Use all POP Sites',
  },
  maxGuaranteedBw: {
    id: 'maxGuaranteedBw',
    defaultMessage: 'Maximize Minimum Guaranteed BW',
  },
  fileUploadSuccess: {
    id: 'fileUploadSuccess',
    defaultMessage: 'File Uploaded Successfully',
  },
  towerSwitchEquipment: {
    id: 'towerSwitchEquipment',
    defaultMessage: 'Tower Switch Equipment',
  },

  // profile
  profileEditor: {
    id: 'profile.edit',
    defaultMessage: 'Profile Editor',
  },
  revertProfile: {
    id: 'profile.revert',
    defaultMessage: 'Revert Profile',
  },
  revertProfileConfirmMessage: {
    id: 'profile.revert.confirm',
    defaultMessage:
      'Reverting the profile will overwrite all existing data including any edits',
  },
  deleteProfilePointsHeader: {
    id: 'delete.profilePointsHeader',
    defaultMessage: 'Delete Points',
  },
  addProfilePointsHeader: {
    id: 'add.profilePointsHeader',
    defaultMessage: 'Add Point',
  },
  addProfilePointsEstimatedHeightTitle: {
    id: 'add.profilePointsEstimatedHeightTitle',
    defaultMessage: 'Estimated height based on selected range',
  },
  addProfilePointsEstimatedObstructionTitle: {
    id: 'add.profilePointsEstimatedObstructionTitle',
    defaultMessage: 'Estimated obstruction based on selected range',
  },
  deleteProfilePointsMessage: {
    id: 'delete.profilePointsMessage',
    defaultMessage:
      'Are you sure you want to delete all points between {startPoint} and {endPoint} {rangeUnits}?.',
  },
  deleteProfilePointMessage: {
    id: 'delete.profilePointMessage',
    defaultMessage:
      'Are you sure you want to delete the point at {deletePoint} {rangeUnits}?.',
  },
  addPoint: {
    id: 'addPoint',
    defaultMessage: 'Add a point to the profile',
  },
  deletePoints: {
    id: 'deletePoints',
    defaultMessage: 'Delete points from the profile',
  },
  interpolateHelp: {
    id: 'interpolate.help',
    defaultMessage:
      'Straight-line interpolation for the terrain or obstruction heights',
  },
  interpolateTerrainHeights: {
    id: 'interpolate.terrain.heights',
    defaultMessage:
      'Adjust terrain heights on selected points to create a straight line between the end points',
  },
  interpolateObstructionHeights: {
    id: 'interpolate.obstruction.heights',
    defaultMessage:
      'Adjust obstruction heights on selected points to create a straight line between the end points',
  },
  interpolateAddPoints: {
    id: 'interpolate.addpoints',
    defaultMessage:
      'Create new points at a set interval along the path. Only available when the path length <= {maxRangeVal} {rangeUnits}',
  },
  enableReflectionMitigation: {
    id: 'enable.reflection.mitigation',
    defaultMessage: 'Enable Reflection Mitigation',
  },
  // PMP450V Select Transmit Frequencies
  selectTransmitFrequencies: {
    id: 'frequency.selectTransmitFrequencies',
    defaultMessage: 'Select Transmit Frequencies',
  },
  componentCarrier: {
    id: 'componentCarrier',
    defaultMessage: 'Component Carrier',
  },

  // help content
  networkDevicesHelp: {
    id: 'networkDeviceHelp',
    defaultMessage: `Define the PMP Network Device broadcast/sector equipment
     (AP, BTS, DN, etc) deployed at the network site. Typically
    these are radios deployed on a tower to connect remote
     subscriber site locations to the network.`,
  },
  viewshedHelp: {
    id: 'viewshedHelp',
    defaultMessage: `Define a viewshed from a given transmit location.
     Viewsheds indicate areas on the map where line-of-sight
     coverage is available. This practice can help determine optimal
     Network Site locations.`,
  },
  meshLinksHelp: {
    id: 'meshLinksHelp',
    defaultMessage: `Define the 60 GHz mesh links that provide
    backhaul between DN devices. Mesh links differ from 60 GHz
    PTP links in that for mesh links the E2E controller can
    pick and choose the path of traffic to optimize the network.`,
  },
  ptpLinksHelp: {
    id: 'ptpLinksHelp',
    defaultMessage: `Define the wireless PTP Link that connects
     two Network Sites. This function has the effect of adding PTP
     radios to the Network Site location.`,
  },
  pmpLinksHelp: {
    id: 'pmpLinksHelp',
    defaultMessage: `Define the wireless PMP Link that connects
     a PMP Network Device (AP, BTS, DN, etc) to a Subscriber Site.
     This function has the effect of adding a radio
     (whether SM, CPE, CN, etc) to the Subscriber Site location.`,
  },
  subscriberSitesHelp: {
    id: 'subscriberSitesHelp',
    defaultMessage: `Define the potential remote locations to be
     connected back wirelessly to your PMP network devices
      (AP, BTS, DN, etc). Typically these would be customer
      locations (homes, businesses, etc).`,
  },
  networkSitesHelp: {
    id: 'networkSitesHelp',
    defaultMessage: `Define the locations where you intend to install your PTP radios
      or your PMP network devices (AP, BTS, DN, etc). Typically this
      would be a tower or rooftop location with good line-of sight
      coverage to the other locations in your network.`,
  },
  mapFilterHelp: {
    id: 'mapFilterHelp',
    defaultMessage: `When this option is enabled, the menu will only show
    items that are located inside the area that is shown in the map.
    This can help to focus the menu on an area of interest for large projects
    that cover a wide area.`,
  },
});
