// This extends Leaflet with the Google layer extensions
import mutant from 'leaflet.gridlayer.googlemutant';

let _baseMaps = {};

// TODO: Get zoom, attribution, etc
const googleLayers = [
  { displayLabel: 'Map', type: 'roadmap' },
  { displayLabel: 'Satellite', type: 'hybrid' },
  { displayLabel: 'Terrain', type: 'terrain' },
];

// Disable POI markers
const styles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];

for (let i = 0; i < googleLayers.length; i++) {
  const layer = googleLayers[i];
  const mapLayer = L.gridLayer.googleMutant({
    type: layer.type,
    displayLabel: layer.displayLabel,
    styles: styles,
  });
  _baseMaps[layer.displayLabel] = mapLayer;
}

export const baseMaps = _baseMaps;
