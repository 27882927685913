import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Grid, Icon, Message, Modal } from 'semantic-ui-react';
import { ColDef, GridApi } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AntennaType, isEqualByKey } from '../ptp/utils';
import ToolBar from './ToolBar';
import { IntlShape, injectIntl } from 'react-intl';
import { getWithAuth } from 'src/api';
import { useParams } from 'react-router-dom';
import {
  defaultColumnDef,
  polarity,
  manufacturerColumn,
  descriptionColumn,
  partNumberColumn,
  directColumn,
  beamwidthColumn,
  sortAntennas,
  isCommonBandExists,
  customAntennaStringComparator,
  customAntennaSizeColumn,
  productsColumn,
  complianceEtsiColumn,
  complianceFccColumn,
  complianceAnatelColumn,
  complianceCanadaColumn,
  complianceMexicoColumn,
  userDefinedColumn,
} from './common/AntennaColumns';
import { Loader } from 'semantic-ui-react';
import { find, get } from 'lodash';
import { deleteCustomAntenna } from './antenna.service';
import { toast } from 'react-toastify';
import { uiConfirmAction } from '../mainframe/mainframe.reducer';
import { store } from 'src/store';
import messages from 'src/messages';
import AntennaFormContainer from './AntennaFormContainer';
import { openAntennaModal } from './antenna.reducer';
import { NOMINAL_FREQUENCY_MAP } from 'src/model/BandDefinition';

const getAntennaTableRows = (antennas, selectedBands) => {
  const filteredAntennas = [];
  antennas.forEach((antennaData) => {
    const { gains, data } = antennaData;
    const modifiedGains = {};
    // filter the data based on selected bands
    if (isCommonBandExists(selectedBands, Object.keys(gains))) {
      // for ag grid replacing '.' with _ for to get the data
      for (const key in gains) {
        modifiedGains[key.replace(/\./g, '_')] = gains[key];
      }
      filteredAntennas.push({
        ...modifiedGains,
        ...data,
      });
    }
  });
  return filteredAntennas;
};

const antennaTableUserDefinedColumn = {
  ...userDefinedColumn,
  sortable: true,
  sort: 'desc',
};

const customAntennaTableColumns: any = {
  ptp: [
    { ...polarity, comparator: customAntennaStringComparator },
    {
      ...manufacturerColumn,
      width: 120,
      comparator: customAntennaStringComparator,
    },
    { ...customAntennaSizeColumn },
    {
      ...descriptionColumn,
      width: 220,
      comparator: customAntennaStringComparator,
    },
    { ...productsColumn, width: 120 },
    {
      ...partNumberColumn,
      width: 80,
      comparator: customAntennaStringComparator,
    },
    { ...directColumn, width: 15 },
    { ...antennaTableUserDefinedColumn },
  ],
  compliance: [
    { ...complianceEtsiColumn, width: 15 },
    { ...complianceFccColumn, width: 15 },
    { ...complianceAnatelColumn, width: 15 },
    { ...complianceCanadaColumn, width: 15 },
    { ...complianceMexicoColumn, width: 15 },
  ],
  nd: [
    { ...beamwidthColumn, width: 30 },
    {
      ...manufacturerColumn,
      width: 120,
      comparator: customAntennaStringComparator,
    },
    {
      ...descriptionColumn,
      width: 220,
      comparator: customAntennaStringComparator,
    },
    { ...productsColumn, width: 140 },
    {
      ...partNumberColumn,
      width: 100,
      comparator: customAntennaStringComparator,
    },
    { ...directColumn, width: 15 },
  ],
  sm: [
    { ...polarity, comparator: customAntennaStringComparator },
    { ...beamwidthColumn, width: 30 },
    { ...customAntennaSizeColumn },
    {
      ...manufacturerColumn,
      width: 120,
      comparator: customAntennaStringComparator,
    },
    {
      ...descriptionColumn,
      width: 220,
      comparator: customAntennaStringComparator,
    },
    { ...productsColumn, width: 140 },
    {
      ...partNumberColumn,
      width: 100,
      comparator: customAntennaStringComparator,
    },
    { ...directColumn, width: 15 },
    { ...antennaTableUserDefinedColumn },
  ],
};

const gainComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  // Convert empty values to zero for comparison
  const numA = valueA === undefined ? 0 : parseFloat(valueA);
  const numB = valueB === undefined ? 0 : parseFloat(valueB);

  // Implement custom logic for comparing numbers
  if (numA < numB) {
    return -1;
  } else if (numA > numB) {
    return 1;
  } else {
    return 0;
  }
};

const getBandColumn = (band): ColDef => ({
  ...defaultColumnDef,
  field: band.replace(/\./g, '_'),
  width: 40,
  headerName: `${band} (dBi)`,
  comparator: gainComparator,
  valueFormatter: (params) => {
    // Ensure the value is a number
    if (typeof params.value === 'number') {
      return params.value.toFixed(1);
    }
    return params.value;
  },
});

const PREVENT_COL_MOVE = {
  suppressMovable: true,
};

const AntennasTable = ({
  selectedBands,
  kind,
  intl,
}: {
  intl: IntlShape;
  selectedBands: any;
  kind: 'ptp' | 'sm';
}) => {
  const gridRef = useRef(null);
  const { antennaType } = useParams();
  const [filter, setFilter] = useState('');
  const [selectedAntennas, setSelectedAntennas] = useState([]);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const projectId = useSelector((state: any) => state.mainFrame.projectId);
  const [antennaFormState, setAntennaFormState] = useState({
    isOpen: false,
    isEdit: false,
  });
  const [formValid, setFormValid] = useState(false);
  const antennaFormRef = useRef(null);
  let maxSelectedFreq = 0;
  // Fetch the antenna data from the backend
  const { data: allAntennas, refetch } = useQuery<AntennaType>({
    queryKey: [projectId, 'antennas', antennaType],
    queryFn: async () => {
      return await getWithAuth(`project/${projectId}/antennas/${antennaType}`);
    },
  });

  const selectedBandColumns: ColDef[] = [];
  const columns: ColDef[] = customAntennaTableColumns[kind];
  const { formatMessage } = intl;
  // Clear the current selection in the table when band changes
  // Without this the Save Antenna Pattern button could be displayed at the wrong time.

  selectedBands.forEach((band) => {
    const bandColumn = getBandColumn(band);
    selectedBandColumns.push(bandColumn);
    if (band !== '60 GHz') {
      // Treat 60 GHz as unlicensed since the antennas don't have compliance settings
      maxSelectedFreq = Math.max(maxSelectedFreq, NOMINAL_FREQUENCY_MAP[band]);
    }
  });
  let visibleColumns = [];
  if (
    kind === 'ptp' &&
    maxSelectedFreq >= NOMINAL_FREQUENCY_MAP['Lower 6 GHz']
  ) {
    // Include the compliance columns when the "licensed" bands are selected
    visibleColumns = [
      ...columns,
      ...selectedBandColumns,
      ...customAntennaTableColumns['compliance'],
    ];
  } else {
    visibleColumns = [...columns, ...selectedBandColumns];
  }

  useEffect(() => {
    const api = get(gridRef, 'current.api');
    if (api && !isEqualByKey(api.getColumnDefs(), visibleColumns, 'field')) {
      setSelectedAntennas([]);
      api.setColumnDefs(visibleColumns);
    }
  }, [visibleColumns]);

  if (!allAntennas) {
    return <Loader active />;
  }

  const filteredAntennas = getAntennaTableRows(
    sortAntennas(allAntennas),
    selectedBands
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = (event) => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedAntennas(selectedData);
    }
  };

  const getAntennaLabel = () => {
    let label = '';
    if (selectedAntennas.length === 1) {
      const { display_name } = selectedAntennas[0];
      label = display_name;
    }
    return label;
  };

  const getConfirmMessageContent = () => {
    return (
      <Grid style={{ padding: '16px' }}>
        <h3>{formatMessage(messages.antennaBuiltInEditMsg)}</h3>
        <p>
          {formatMessage(messages.builtInEditConfirmPrompt, {
            label: getAntennaLabel(),
          })}
        </p>
      </Grid>
    );
  };

  const handleAction = (action: 'edit' | 'add' | 'delete' | 'toggle') => {
    switch (action) {
      case 'add':
      case 'toggle': {
        store.dispatch(openAntennaModal({ isEdit: false }));
        break;
      }
      case 'edit': {
        if (selectedAntennas[0].user_defined) {
          store.dispatch(
            openAntennaModal({ isEdit: true, formData: getFormData() })
          );
        } else {
          store.dispatch(
            uiConfirmAction({
              header: 'Built-in Antenna',
              message: getConfirmMessageContent(),
              size: 'tiny',
              cancelButton: 'No',
              confirmButton: 'Yes',
              onConfirm: () => {
                store.dispatch(
                  openAntennaModal({ isEdit: true, formData: getFormData() })
                );
              },
            })
          );
        }
        break;
      }
      case 'delete': {
        deleteCustomAntenna(projectId, selectedAntennas[0].id, kind)
          .then((result) => {
            toast(<Message positive>{'Antenna Deleted.'}</Message>, {
              autoClose: false,
            });
            refetch();
            setSelectedAntennas([]);
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      }
    }
  };

  const getFormData = () => {
    if (selectedAntennas.length) {
      const selectedAntenna = { ...selectedAntennas[0] };
      const { id, user_defined } = selectedAntenna;
      const antenna = find(allAntennas, { id });
      if (antenna) {
        selectedAntenna['diameter'] = Number(
          selectedAntenna['diameter'] * selectedAntenna['diameter_scale_factor']
        ).toFixed(1);
        const { gains, data } = antenna;
        selectedAntenna.gains = gains;
        if (!user_defined) {
          selectedAntenna.description = data.description + ' (copy)';
        }
        return selectedAntenna;
      }
    }
    return {};
  };

  return (
    <div className="ag-theme-alpine generic-grid">
      <ToolBar
        gridApi={gridApi}
        filter={filter}
        setFilter={setFilter}
        selectedAntennas={selectedAntennas}
        handleAction={(action) => handleAction(action)}
      />
      <AgGridReact
        quickFilterText={filter}
        ref={gridRef}
        rowData={filteredAntennas}
        applyColumnDefOrder={true}
        suppressDragLeaveHidesColumns={true}
        pagination={false}
        defaultColDef={PREVENT_COL_MOVE}
        rowSelection="single"
        onGridReady={onGridReady}
        getRowNodeId={(data) => {
          return data.id;
        }}
        onSelectionChanged={onSelectionChanged}
        rowHeight={28}
        gridOptions={{
          domLayout: 'autoHeight',
          getRowStyle: (params) => {
            const { data, context } = params;
            const { id, is_obsolete, internal, user_defined } = data;
            let style: {
              fontWeight: string;
              color?: string;
              fontStyle?: string;
            } = {
              fontWeight: '700',
            };
            const selectedRowStyle = { backgroundColor: '#BCDFFB' };
            const selected = id == context?.rowId;
            if (is_obsolete) {
              style = {
                color: 'grey',
                fontStyle: 'italic',
                fontWeight: '400',
              };
            } else if (!internal) {
              style = {
                fontWeight: '400',
              };
            }
            if (selected) {
              style = { ...style, ...selectedRowStyle };
            }
            if (user_defined) {
              style = {
                fontWeight: '700',
              };
            }
            return style;
          },
          enableBrowserTooltips: true,
        }}
      ></AgGridReact>
      <AntennaFormContainer kind={kind} refetch={() => refetch()} />
    </div>
  );
};

export default injectIntl(AntennasTable);
