import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import messages from 'src/messages';
import { useSelector } from 'react-redux';
import { encodeLatLong } from 'src/utils/useful_functions';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { generateSiteNameFromTemplate } from 'src/utils/mapUtils';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { parseCoords } from '../map/SiteModePanel';
import { postWithAuth } from 'src/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setDefaultHeight } from '../map/map.reducer';

const DEFAULT_SITE_FORM_VALUES = {
  siteName: '',
  // Don't set the so that the default values are used
  // coordinates: '',
  // maximumHeight: 10,
  nodeType: '',
  description: '',
};

const getCoordsString = (lat, lng, latLngFormat) => {
  return `${encodeLatLong(lat, latLngFormat, true)}, ${encodeLatLong(
    lng,
    latLngFormat,
    false
  )}`;
};

const createSite = ({
  projectId,
  isNetwork,
  siteName,
  coordinates,
  maximumHeight,
  nodeType,
  description,
}) => {
  const { lat, lng } = parseCoords(coordinates);
  postWithAuth(`project/${projectId}/site`, {
    name: siteName,
    latitude: lat,
    longitude: lng,
    is_network_site: isNetwork,
    maximum_height: maximumHeight,
    node_type: nodeType,
    description: description,
  })
    .then((data) => {
      //do nothing for now
    })
    .catch((err) => {
      if (err.detail) {
        toast(<Message error>{err.detail}</Message>, {
          autoClose: false,
        });
      }
    });
};

const NewSiteModal = ({ siteKind, open, close }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [prefs, projectId, networkSiteCount, subscriberSiteCount] = useSelector(
    (state) => [
      state.mainFrame.prefs,
      state.mainFrame.projectId,
      state.mainFrame.networkSiteCount,
      state.mainFrame.subscriberSiteCount,
    ]
  );
  const [defaultHeight, networkSiteNameTemplate, subscriberSiteNameTemplate] =
    useSelector((state) => [
      state.map.defaultHeight,
      state.map.networkSiteNameTemplate,
      state.map.subscriberSiteNameTemplate,
    ]);

  const formMethods = useForm({
    defaultValues: DEFAULT_SITE_FORM_VALUES,
  });
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    reset,
    formState: { isDirty, errors },
  } = formMethods;

  const { heightUnits, latLngFormat } = prefs;

  const isNetwork = siteKind == 'network_site';
  const siteNameTemplate = isNetwork
    ? networkSiteNameTemplate
    : subscriberSiteNameTemplate;

  const defaultSiteName = generateSiteNameFromTemplate({
    isNetwork,
    siteNameTemplate,
    networkSiteCount,
    subscriberSiteCount,
  });

  useEffect(() => {
    // Force the default site name to refresh
    // if there is a change outside of the panel
    setValue('siteName', defaultSiteName);
  }, [open, siteNameTemplate, networkSiteCount, subscriberSiteCount]);

  const resetAndClose = () => {
    reset(DEFAULT_SITE_FORM_VALUES);
    close();
  };

  const onSubmit = async (formData) => {
    createSite({ projectId, isNetwork, ...formData });
    dispatch(setDefaultHeight(formData.maximumHeight));
    resetAndClose();
  };

  return (
    <Modal
      open={open}
      size="mini"
      closeOnDimmerClick
      onClose={() => resetAndClose()}
    >
      <FormProvider {...formMethods}>
        <Modal.Header>
          New Site
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={() => resetAndClose()}
          />
        </Modal.Header>

        <Modal.Content>
          <Form method="post">
            <Controller
              control={control}
              name="siteName"
              defaultValue={defaultSiteName}
              rules={{
                required: true,
                validate: {
                  siteName: (value) => {
                    return value.trim().length > 0;
                  },
                },
              }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.Input
                  label={formatMessage(messages.siteName)}
                  required
                  {...rest}
                  error={errors['siteName'] != null}
                  onChange={(e, { value }) => {
                    onChange(value);
                    trigger();
                  }}
                ></Form.Input>
              )}
            />

            <GenericScaledField
              key={'maximumHeight'}
              getter="maximumHeight"
              alwaysEnabled={false}
              required={true}
              precision={1}
              label={'Maximum Height'}
              defaultValue={defaultHeight}
              min={0}
              max={3000}
              units={heightUnits}
            ></GenericScaledField>

            <Controller
              control={control}
              name="coordinates"
              rules={{
                required: true,
                validate: {
                  coordinates: (value) => {
                    const { valid } = parseCoords(value);
                    return valid;
                  },
                },
              }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.Input
                  required
                  placeholder={getCoordsString(0, 0, latLngFormat)}
                  autoFocus
                  error={errors['coordinates'] != null}
                  {...rest}
                  label={'Coordinates'}
                  onChange={(e, { value }) => {
                    onChange(value);
                    // Force validation
                    trigger();
                  }}
                ></Form.Input>
              )}
            />

            {isNetwork && (
              <Controller
                control={control}
                name={'nodeType'}
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Form.Select
                    label={'Node Type'}
                    options={[
                      { key: '', value: '', text: '' },
                      { key: 'DN', value: 'DN', text: 'DN' },
                      { key: 'POP', value: 'POP', text: 'POP' },
                    ]}
                    onChange={(e, { value }) => {
                      onChange(value);
                    }}
                    {...rest}
                  ></Form.Select>
                )}
              ></Controller>
            )}
            <Controller
              control={control}
              name={'description'}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.TextArea
                  {...rest}
                  label={'Description'}
                  maxLength={500}
                  onChange={(e, { value }) => {
                    onChange(value.slice(0, 500));
                  }}
                ></Form.TextArea>
              )}
            ></Controller>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <div className="modal-actions">
            <Button onClick={() => resetAndClose()}>
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={
                !isEmpty(errors) || !isDirty || !getValues('coordinates')
              }
              onClick={handleSubmit(onSubmit)}
            >
              {formatMessage(messages.ok)}
            </Button>
          </div>
        </Modal.Actions>
      </FormProvider>
    </Modal>
  );
};

export default NewSiteModal;
