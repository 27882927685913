import React, { useEffect, useRef, useState } from 'react';
import { Segment, Dimmer, Loader, Message } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { store } from '../../store';
import { postWithAuth } from '../../api';
import additionalMessages from '../../messages';
import { fetchSites, uiConfirmAction } from '../mainframe/mainframe.reducer';
import { makePlural } from '../../utils/useful_functions';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import messages from '../../messages';
import {
  ADD_EDIT_TOWERSWITCH,
  CONVERT_TO_NETWORK_SITE,
  CONVERT_TO_SUBSCRIBER_SITE,
  CREATE_DUPLICATE_NETWORK_SITE,
  CREATE_DUPLICATE_SUBSCRIBER_SITE,
  DELETE_ACTION_TOOLBAR,
  DELETE_TOWERSWITCH,
} from 'src/app.constants';
import NetworkSiteBomModal from 'src/pages/sites/networksites/NetworkSiteBomModal';
import { toast } from 'react-toastify';
import { TOAST_AUTOCLOSE } from '../terragraph/TerragraphPanel';
import { PanelHeading } from 'src/components/PanelHeading';
import { useSelector } from 'react-redux';
import { getNetworkSiteColumns, getSubscriberSiteColumns } from './SiteColumns';
import NewSiteModal from './NewSiteModal';

const DEFAULT_STATE = {
  showNewSiteModal: false,
  showLoader: false,
  showTowerSwitch: false,
  selectedSiteId: null,
  selectedSiteIds: [],
};

const deleteSites = (
  formatMessage,
  projectId,
  selectedItems,
  panelKind,
  tableRef,
  updateState
) => {
  store.dispatch(
    uiConfirmAction({
      header: formatMessage(additionalMessages.deleteSites),
      message: formatMessage(additionalMessages.confirm),
      size: 'mini',
      onConfirm: () => {
        postWithAuth(
          `project/${projectId}/sites`,
          {
            kind: panelKind,
            ids: selectedItems,
          },
          'DELETE'
        ).then(() => {
          store.dispatch(fetchSites(projectId));
          updateState({ selectedSiteIds: [] });
          if (tableRef) {
            tableRef.current?.refresh();
          }
        });
      },
    })
  );
};

function deleteTowerSwitches(params, projectId, formatMessage, tableRef) {
  const selectedRows = params.gridApi.getSelectedRows();
  const selectedSiteIds = selectedRows.map((row) => row.id);
  store.dispatch(
    uiConfirmAction({
      header: 'Delete Tower Switches',
      message: formatMessage(additionalMessages.confirm),
      size: 'mini',
      onConfirm: () => {
        postWithAuth(
          `project/${projectId}/site/${selectedSiteIds[0]}/tower_switch`,
          {
            site_ids: selectedSiteIds.slice(1),
          },
          'DELETE'
        )
          .then(() => {
            if (tableRef.current) {
              tableRef.current.refresh();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
    })
  );
}

const convertSites = (
  params,
  projectId,
  isNetworkSite,
  refreshTable,
  isSiteConversion = false
) => {
  const selectedRows = params.gridApi.getSelectedRows();
  const sites = selectedRows.map((row) => {
    return {
      name: row.name,
      latitude: row.latitude,
      longitude: row.longitude,
      // if we are currently on a network site
      // then convert to subscriber site
      is_network_site: !isNetworkSite,
      maximum_height: row.maximum_height,
      description: row.description,
    };
  });

  let errorMsg = '';
  postWithAuth(`project/${projectId}/sites`, sites, 'POST').catch((err) => {
    errorMsg = err.detail;
    console.error(err);
  });

  if (errorMsg) {
    toast(<Message negative>{errorMsg}</Message>, {
      autoClose: false,
    });
  } else {
    store.dispatch(fetchSites(projectId));
    refreshTable();

    const label = makePlural(sites.length, 'site', 'sites');
    const changeType = isSiteConversion ? 'converted' : 'duplicated';
    const message = `${sites.length} ${label} ${changeType}`;

    toast(<Message positive>{message}</Message>, {
      autoClose: TOAST_AUTOCLOSE,
    });
  }
};

const getTableActions = (
  tableRef,
  panelKind,
  projectId,
  formatMessage,
  refreshTable,
  permissionWrite,
  currentState,
  updateState
) => {
  const isNetworkSite = panelKind === 'network_site';
  const otherSiteKind = isNetworkSite ? 'Subscriber' : 'Network';
  const commonActions = [
    {
      icon: 'add',
      label: formatMessage(messages.add),
      onClick: (event, data, params) => {
        updateState({ showNewSiteModal: true });
      },
      disabled: !permissionWrite,
      id: 'add',
    },
    {
      icon: 'trash alternate',
      label: formatMessage(messages.delete),
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        const selectedSiteIds = selectedRows.map((row) => row.id);
        deleteSites(
          formatMessage,
          projectId,
          selectedSiteIds,
          panelKind,
          tableRef,
          updateState
        );
      },
      disabled: true,
      ...DELETE_ACTION_TOOLBAR,
    },
  ];

  const convertSiteActions = [
    {
      icon: 'exchange',
      label: `Convert To ${otherSiteKind} Site`,
      id: isNetworkSite ? CONVERT_TO_SUBSCRIBER_SITE : CONVERT_TO_NETWORK_SITE,
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        store.dispatch(
          uiConfirmAction({
            header: 'Convert Site?',
            message: isNetworkSite
              ? formatMessage(messages.convertNetworkSiteMessage)
              : formatMessage(messages.convertSubscriberSiteMessage),
            size: 'mini',
            onConfirm: () => {
              convertSites(
                params,
                projectId,
                isNetworkSite,
                refreshTable,
                true
              );
              postWithAuth(
                `project/${projectId}/sites`,
                {
                  kind: panelKind,
                  ids: selectedRows.map((el) => el.id),
                },
                'DELETE'
              )
                .then(() => {
                  store.dispatch(fetchSites(projectId));
                  refreshTable();
                })
                .catch((err) => {
                  console.error(err);
                });
            },
          })
        );
      },
      disabled: !permissionWrite,
    },
    {
      icon: 'exchange',
      label: `Create Duplicate ${otherSiteKind} Site`,
      id: isNetworkSite
        ? CREATE_DUPLICATE_SUBSCRIBER_SITE
        : CREATE_DUPLICATE_NETWORK_SITE,
      onClick: (event, data, params) => {
        convertSites(params, projectId, isNetworkSite, refreshTable);
      },
      disabled: !permissionWrite,
    },
  ];

  if (isNetworkSite) {
    const switchActions = [
      {
        icon: 'plus',
        label: 'Add/Edit Tower Switch',
        id: ADD_EDIT_TOWERSWITCH,
        onClick: (event, data, params) => {
          updateState({
            selectedSiteId: params.gridApi.getSelectedRows()[0].id,
            showTowerSwitch: true,
            selectedSiteIds: params.gridApi
              .getSelectedRows()
              .map((row) => row.id),
          });
        },
        disabled: !permissionWrite || currentState.selectedSiteIds.length === 0,
      },
      {
        icon: 'trash alternate',
        label: 'Delete Tower Switch',
        id: DELETE_TOWERSWITCH,
        onClick: (event, data, params) => {
          deleteTowerSwitches(params, projectId, formatMessage, tableRef);
        },
        disabled: !permissionWrite || currentState.selectedSiteIds.length === 0,
      },
    ];
    return [...commonActions, ...switchActions, ...convertSiteActions];
  } else {
    return [...commonActions, ...convertSiteActions];
  }
};

const SitesPanel = ({ heading, path, panelKind, groupKind }) => {
  const { formatMessage } = useIntl();
  const tableRef = useRef();

  const [currentState, setState] = useState(DEFAULT_STATE);
  const {
    showNewSiteModal,
    showLoader,
    showTowerSwitch,
    selectedSiteId,
    selectedSiteIds,
  } = currentState;

  const [
    projectId,
    projectName,
    networkSiteCount,
    subscriberSiteCount,
    permissionWrite,
  ] = useSelector((state) => [
    state.mainFrame.projectId,
    state.mainFrame.projectName,
    state.mainFrame.networkSiteCount,
    state.mainFrame.subscriberSiteCount,
    state.mainFrame.permissionWrite,
  ]);

  const itemCount = {
    network_sites: networkSiteCount,
    subscriber_sites: subscriberSiteCount,
  }[groupKind];

  const updateState = (changes) => {
    setState({ ...currentState, ...changes });
  };

  const refreshTable = () => {
    if (tableRef?.current?.refresh) {
      tableRef.current.refresh();
    }
  };

  useEffect(() => {
    refreshTable();
  }, [itemCount]);

  const actions = getTableActions(
    tableRef,
    panelKind,
    projectId,
    formatMessage,
    refreshTable,
    permissionWrite,
    currentState,
    updateState
  );

  const columns =
    panelKind === 'network_site'
      ? getNetworkSiteColumns(path, projectId)
      : getSubscriberSiteColumns(path, projectId);

  return (
    <>
      <NewSiteModal
        siteKind={panelKind}
        open={showNewSiteModal}
        close={() => updateState({ showNewSiteModal: false })}
      />

      {showTowerSwitch && (
        <NetworkSiteBomModal
          objId={selectedSiteId}
          kind="network_site"
          url={`project/${projectId}/site/${selectedSiteId}/bom`}
          tsurl={`project/${projectId}/site/${selectedSiteId}/tower_switch`}
          selectedSiteIds={selectedSiteIds}
          tableViewShowTowerSwitch={showTowerSwitch}
          tableViewResetShowTowerSwitch={(value) => {
            updateState({
              showTowerSwitch: value,
            });
            refreshTable();
          }}
        />
      )}

      <PanelHeading
        title={
          <>
            {formatMessage(heading)} ({itemCount})
          </>
        }
      />
      <Segment basic fireOnMount>
        <Dimmer inverted active={showLoader}>
          <Loader size="large" indeterminate content="Loading..." active />
        </Dimmer>
        <div className="detailWrapper">
          <Segment basic style={{ width: '100%', maxHeight: '80vh' }}>
            {
              // Hack to hid the grid when the modals are shown
              // so that the grid toolbar refreshes correctly when
              // the modal is closed. This can be removed when
              // we remove LPGrid
            }
            {!(showTowerSwitch || showNewSiteModal) && (
              <LPGrid
                url={`project/${projectId}/sites?kind=${panelKind}`}
                gridRef={tableRef}
                actions={actions}
                table_id={panelKind}
                isTableColConfigure={true}
                defaultCsvExportParams={{
                  fileName: `${projectName}_${groupKind}.csv`,
                }}
                columnDefs={columns}
              ></LPGrid>
            )}
          </Segment>
        </div>
      </Segment>
    </>
  );
};

export const NetworkSitesPanel = (props) => (
  <SitesPanel
    heading={additionalMessages.networkSites}
    path="/network_sites"
    panelKind="network_site"
    groupKind="network_sites"
  />
);

export const SubscriberSitesPanel = (props) => (
  <SitesPanel
    heading={additionalMessages.subscriberSites}
    path="/subscriber_sites"
    panelKind="subscriber_site"
    groupKind="subscriber_sites"
  />
);
