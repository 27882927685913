import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import L from 'leaflet';
import { Form, Loader, Table } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { postWithAuth } from 'src/api';
import geoBoundaries from './countries.json';
import canadaBoundaries from './canada_provinces.json';
import usBoundaries from './us_states.json';
import { isEmpty } from 'lodash';

let REGION_BOUNDARIES = {};

geoBoundaries.features.forEach((f) => {
  const cc = f.properties.country_code;
  const sub = f.properties.subdivision;
  if (f.geometry) {
    if (sub) {
      REGION_BOUNDARIES[`${cc}-${sub}`] = f;
    } else {
      REGION_BOUNDARIES[cc] = f;
    }
  }
});

usBoundaries.features.forEach((f) => {
  const cc = f.properties.country_code;
  const sub = f.properties.subdivision;
  REGION_BOUNDARIES[`${cc}-${sub}`] = f;
});

canadaBoundaries.features.forEach((f) => {
  const cc = f.properties.country_code;
  const sub = f.properties.subdivision;
  REGION_BOUNDARIES[`${cc}-${sub}`] = f;
});

const dayMultiplier = 24 * 60 * 60 * 1000;

function dateDifferenceDays(start, end) {
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / dayMultiplier);
}

// 30 days
const defaultStart = new Date(new Date().getTime() - 30 * dayMultiplier);

const DEFAULT_DATES = {
  //   startDate: new Date('1 Jan 2020'),
  startDate: defaultStart,
  endDate: new Date(),
  days: dateDifferenceDays(defaultStart, new Date()),
};

// https://colorbrewer2.org/#type=sequential&scheme=YlGn&n=9
const colourPaletteGreen = [
  /* Greens */
  '#ffffe5',
  '#f7fcb9',
  '#d9f0a3',
  '#addd8e',
  '#78c679',
  '#41ab5d',
  '#238443',
  '#006837',
  '#004529',
];
const colourPaletteBlue = [
  /* Blues */
  '#fff7fb',
  '#ece7f2',
  '#d0d1e6',
  '#a6bddb',
  '#74a9cf',
  '#3690c0',
  '#0570b0',
  '#045a8d',
  '#023858',
];
const colourPaletteRed = [
  /* Reds */
  '#fde0dd',
  '#fcc5c0',
  '#fa9fb5',
  '#f768a1',
  '#dd3497',
  '#ae017e',
  '#7a0177',
  '#49006a',
];

export function StatsMap() {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: DEFAULT_DATES,
  });

  const mapRef = useRef('statsmap');

  const [palette, setPalette] = useState(colourPaletteGreen);

  const { startDate, endDate, days } = getValues();
  const queryKey = ['project-count-map'];

  const { data: projectsPerCountry, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      let { startDate, endDate } = getValues();
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(0, 0, 0, 0);
      return await postWithAuth(`admin/projects_per_country`, {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      });
    },
    initialData: null,
    enabled: true,
  });

  const qc = useQueryClient();

  const onSubmit = () => {
    qc.removeQueries({
      queryKey: queryKey,
    });
  };

  if (isFetching) {
    return <Loader active inline />;
  }

  const onDayChange = (newDays) => {
    const newStartDate = new Date(
      endDate.getTime() - (newDays - 1) * dayMultiplier
    );
    setValue('startDate', newStartDate);
  };

  const paletteOptions = [
    {
      key: 'Green',
      value: colourPaletteGreen,
      text: 'Green',
    },
    {
      key: 'Blue',
      value: colourPaletteBlue,
      text: 'Blue',
    },
    {
      key: 'Red',
      value: colourPaletteRed,
      text: 'Red',
    },
  ];

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '1rem',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <Form.Group>
          <div className="stats-map">
            <div>
              <label>Start Date:</label>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    onBlur={onBlur}
                    selected={value}
                    onChange={(newStartDate) => {
                      resetField('days', {
                        defaultValue: dateDifferenceDays(newStartDate, endDate),
                      });
                      onChange(newStartDate);
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </div>
            <div>
              <label>End Date:</label>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    onBlur={onBlur}
                    selected={value}
                    onChange={(newEndDate) => {
                      resetField('days', {
                        defaultValue: dateDifferenceDays(startDate, newEndDate),
                      });
                      onChange(newEndDate);
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </div>
            <div>
              <label>Number of Days:</label>
              <Controller
                control={control}
                name="days"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Form.Input
                    type="number"
                    name="days"
                    defaultValue={days}
                    onBlur={onBlur}
                    selected={value}
                    onChange={(e, { value }) => {
                      if (value === '' || !/^[0-9]+$/.test(value)) {
                        setValue(
                          'startDate',
                          new Date(Date.now() - dayMultiplier)
                        );
                        return;
                      }
                      const newDays = parseInt(value);
                      onDayChange(newDays);
                      onChange(newDays);
                    }}
                  />
                )}
              />
            </div>

            <div>
              <label>Colours:</label>
              <Form.Select
                options={paletteOptions}
                onChange={(e, { value }) => {
                  setPalette(value);
                  onSubmit();
                }}
                value={palette}
              />
            </div>

            <Form.Button
              color="blue"
              type="submit"
              style={{ marginTop: '1.8em' }}
              width={1}
              disabled={!isEmpty(errors) || !isDirty}
            >
              Submit
            </Form.Button>
          </div>
        </Form.Group>

        <div className="stats-map-container">
          <Choropleth
            mapRef={mapRef}
            countsPerCountry={projectsPerCountry}
            palette={palette}
          />
          <CountryCodeCountTable countsPerCountry={projectsPerCountry} />
        </div>

        <div>
          Boundaries from{' '}
          <a href="https://www.geoboundaries.org">
            https://www.geoboundaries.org
          </a>{' '}
          and then simplified with{' '}
          <a href="https://mapshaper.org">https://mapshaper.org</a>.
        </div>
      </Form>
    </>
  );
}

function getColor(palette, props, maxCount) {
  const count = props.count;
  const step = palette.length / maxCount;

  const idx = Math.floor(step * count);
  return palette[Math.min(palette.length - 1, idx)];
}

function createStyle(maxCount, palette) {
  return (feature) => {
    return {
      fillColor: getColor(palette, feature.properties, maxCount),
      weight: 1,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
    };
  };
}

function highlightFeature(e) {
  var layer = e.target;

  layer.setStyle({
    weight: 3,
    color: '#666',
    dashArray: '',
    fillOpacity: 0.7,
  });

  layer.bringToFront();

  const props = layer.feature.properties;
  document.getElementById('stats-map-info').innerHTML = `
${props.name} / ${props.subdivision || props.country_code} : ${props.count}`;
}

function initZoom(map) {
  return (e) => {
    map.fitBounds(e.target.getBounds());
  };
}

const Choropleth = ({ mapRef, countsPerCountry, palette }) => {
  const infoRef = useRef('stats-map-info');

  useEffect(() => {
    const map = L.map('statsmap', {
      keyboard: false,
      worldCopyJump: true,
      preferCanvas: true,
      zoomControl: false,
    }).setView([0, 0], 2);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    let maxCount = 0;
    const newFeatures = [];
    Object.keys(countsPerCountry).forEach((cc) => {
      const count = countsPerCountry[cc];
      maxCount = Math.max(maxCount, count);
      const feature = REGION_BOUNDARIES[cc];
      if (feature) {
        feature.properties.count = count;
        newFeatures.push(feature);
      } else {
        console.log('Warning: cannot find', cc, count);
      }
    });

    const countBoundaries = {
      type: 'FeatureCollection',
      features: newFeatures,
    };

    const styleCreator = createStyle(maxCount, palette);
    const boundaryLayer = L.geoJson(countBoundaries, {
      style: styleCreator,
      onEachFeature: (feature, layer) => {
        layer.on({
          mouseover: highlightFeature,
          mouseout: (event) => {
            const layer = event.target;
            layer.setStyle(styleCreator(layer.feature));
            layer.bringToFront();
            document.getElementById('stats-map-info').innerHTML = '';
          },
          click: initZoom(map),
        });
      },
    });

    boundaryLayer.addTo(map);
  }, []);

  return (
    <>
      <div id="statsmap" ref={mapRef} className="map"></div>{' '}
      <div id="stats-map-info" ref={infoRef}></div>
    </>
  );
};

const CountryCodeCountTable = ({ countsPerCountry, maxResults = 15 }) => {
  const sortedKeys = Object.keys(countsPerCountry).sort((k1, k2) => {
    return countsPerCountry[k2] - countsPerCountry[k1];
  });
  const total = Object.values(countsPerCountry).reduce((a, v) => a + v, 0);
  const sortedResults = sortedKeys.slice(0, maxResults).map((k) => (
    <Table.Row key={`row-${k}`}>
      <Table.Cell key={`cell-${k}`}>{k}</Table.Cell>
      <Table.Cell key={`count-cell-${k}`}>{countsPerCountry[k]}</Table.Cell>
    </Table.Row>
  ));
  return (
    <div className="stats-map-counts-table">
      <h3>Counts (Top {maxResults})</h3>
      Total Count: {total}
      <Table celled>
        <Table.Row>
          <Table.HeaderCell key="h1">Country Code</Table.HeaderCell>
          <Table.HeaderCell key="h2">Count</Table.HeaderCell>
        </Table.Row>
        {sortedResults}
      </Table>
    </div>
  );
};
