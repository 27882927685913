import React, { useState } from 'react';
import { Modal, Button, Tab, TabPane, Accordion } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import UserStatsPanel from '../../components/UserStatsPanel';
import UserInfoSettings from './UserInfoSettings';
import StoredAccordion from 'src/components/StoredAccordion';
import { ProjectToolsForm } from './ProjectToolsForm';
import { useQueryClient } from '@tanstack/react-query';
import { StatsMap } from './StatsMap';

function ProjectTools() {
  return (
    <Accordion exclusive={false} fluid>
      <StoredAccordion name="AdminProjectControls" title="Find a project">
        <ProjectToolsForm />
      </StoredAccordion>
    </Accordion>
  );
}

function AdminTools(props) {
  const [open, setOpen] = useState(true);
  const { formatMessage } = props.intl;
  const qc = useQueryClient();

  const onCloseHandler = () => {
    setOpen(false);
    qc.removeQueries({
      queryKey: ['admin', 'viewProject'],
      exact: false,
    });
    qc.removeQueries({
      queryKey: ['admin', 'findProject'],
      exact: true,
    });
    props.onClose();
  };

  return (
    <Modal
      onClose={onCloseHandler}
      onOpen={() => setOpen(true)}
      open={open}
      centered={false}
      size="large"
      style={{ width: '100%', height: '90%' }}
    >
      <Modal.Header>
        {formatMessage(additionalMessages.adminTools)}
        <Button
          circular
          icon="close"
          title="Close"
          onClick={(e) => onCloseHandler()}
          floated="right"
        />
      </Modal.Header>
      <Modal.Content>
        <Tab
          panes={[
            {
              menuItem: 'Users',
              render: () => (
                <TabPane>
                  <UserInfoSettings />
                </TabPane>
              ),
            },
            {
              menuItem: 'Project Map',
              render: () => (
                <TabPane>
                  <StatsMap />
                </TabPane>
              ),
            },
            {
              menuItem: 'User Stats',
              render: () => (
                <TabPane>
                  <UserStatsPanel />
                </TabPane>
              ),
            },
            {
              menuItem: 'Projects',
              render: () => (
                <TabPane>
                  <ProjectTools />
                </TabPane>
              ),
            },
          ]}
        />
      </Modal.Content>
    </Modal>
  );
}

export default AdminTools;
